import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewEncapsulation,
  ChangeDetectorRef,
  Renderer,
  ViewChild,
  forwardRef,
  OnDestroy
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  IMyDate,
  IMyDateRange,
  IMyMonth,
  IMyCalendarDay,
  IMyCalendarMonth,
  IMyCalendarYear,
  IMyWeek,
  IMyDayLabels,
  IMyMonthLabels,
  IMyOptions,
  IMyDateModel,
  IMyInputFieldChanged,
  IMyCalendarViewChanged,
  IMyInputFocusBlur,
  IMyMarkedDates,
  IMyMarkedDate,
  IMyDateFormat
} from './interfaces/index';
import { LocaleService } from './services/my-date-picker.locale.service';
import { UtilService } from './services/my-date-picker.util.service';

// webpack1_
//declare var require: any;
//const myDpStyles: string = require("./my-date-picker.component.css");
//const myDpTpl: string = require("./my-date-picker.component.html");
// webpack2_

export const MYDP_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MyDatePicker),
  multi: true
};

enum CalToggle { Open = 1, CloseByDateSel = 2, CloseByCalBtn = 3, CloseByOutClick = 4, CloseByEsc = 5, CloseByApi = 6 }

enum Year { min = 1000, max = 9999 }

enum InputFocusBlur { focus = 1, blur = 2 }

enum KeyCode { enter = 13, esc = 27, space = 32 }

enum MonthId { prev = 1, curr = 2, next = 3 }

const MMM = 'mmm';

@Component({
  selector: 'my-date-picker',
  exportAs: 'mydatepicker',
  styleUrls: ['./my-date-picker.component.css'],
  templateUrl: './my-date-picker.component.html',
  providers: [LocaleService, UtilService, MYDP_VALUE_ACCESSOR],
  encapsulation: ViewEncapsulation.None
})

export class MyDatePicker implements OnChanges, ControlValueAccessor, OnDestroy {
  globalListener: Function;
  @Input() numbers: any[] = null;


  @Input() options: IMyOptions;
  @Input() locale: string;
  @Input() defaultMonth: string;
  @Input() selDate: string;
  @Input() placeholder: string;
  @Input() selector: number;
  @Input() disabled: boolean;
  @Output() dateChanged: EventEmitter<IMyDateModel> = new EventEmitter<IMyDateModel>();
  @Output() inputFieldChanged: EventEmitter<IMyInputFieldChanged> = new EventEmitter<IMyInputFieldChanged>();
  @Output() calendarViewChanged: EventEmitter<IMyCalendarViewChanged> = new EventEmitter<IMyCalendarViewChanged>();
  @Output() calendarToggle: EventEmitter<number> = new EventEmitter<number>();
  @Output() inputFocusBlur: EventEmitter<IMyInputFocusBlur> = new EventEmitter<IMyInputFocusBlur>();
  @ViewChild('selectorEl') selectorEl: ElementRef;
  @ViewChild('inputBoxEl') inputBoxEl: ElementRef;

  onChangeCb: (_: any) => void = () => {
  };
  onTouchedCb: () => void = () => {
  };

  showSelector: boolean = false;
  visibleMonth: IMyMonth = { monthTxt: '', monthNbr: 0, year: 0 };
  selectedMonth: IMyMonth = { monthTxt: '', monthNbr: 0, year: 0 };
  selectedDate: IMyDate = { year: 0, month: 0, day: 0 };
  weekDays: Array<string> = [];
  dates: Array<IMyWeek> = [];
  months: Array<Array<IMyCalendarMonth>> = [];
  years: Array<Array<IMyCalendarYear>> = [];
  selectionDayTxt: string = '';
  invalidDate: boolean = false;
  disableTodayBtn: boolean = false;
  dayIdx: number = 0;

  selectMonth: boolean = false;
  selectYear: boolean = false;

  prevMonthDisabled: boolean = false;
  nextMonthDisabled: boolean = false;
  prevYearDisabled: boolean = false;
  nextYearDisabled: boolean = false;
  prevYearsDisabled: boolean = false;
  nextYearsDisabled: boolean = false;

  prevMonthId: number = MonthId.prev;
  currMonthId: number = MonthId.curr;
  nextMonthId: number = MonthId.next;

  // Default options
  opts: IMyOptions = {
    dayLabels: <IMyDayLabels>{},
    monthLabels: <IMyMonthLabels>{},
    dateFormat: <string>'',
    showTodayBtn: <boolean>true,
    todayBtnTxt: <string>'',
    firstDayOfWeek: <string>'',
    satHighlight: <boolean>false,
    sunHighlight: <boolean>true,
    highlightDates: <Array<IMyDate>>[],
    markCurrentDay: <boolean>true,
    markCurrentMonth: <boolean>true,
    markCurrentYear: <boolean>true,
    disableUntil: <IMyDate>{ year: 0, month: 0, day: 0 },
    disableSince: <IMyDate>{ year: 0, month: 0, day: 0 },
    disableDays: <Array<IMyDate>>[],
    enableDays: <Array<IMyDate>>[],
    markDates: <Array<IMyMarkedDates>>[],
    markWeekends: <IMyMarkedDate>{},
    disableDateRanges: <Array<IMyDateRange>>[],
    disableWeekends: <boolean>false,
    disableWeekdays: <Array<string>>[],
    showWeekNumbers: <boolean>false,
    height: <string>'34px',
    width: <string>'100%',
    selectionTxtFontSize: <string>'14px',
    selectorHeight: <string>'232px',
    selectorWidth: <string>'252px',
    allowDeselectDate: <boolean>false,
    inline: <boolean>false,
    showClearDateBtn: <boolean>true,
    showDecreaseDateBtn: <boolean>false,
    showIncreaseDateBtn: <boolean>false,
    alignSelectorRight: <boolean>false,
    openSelectorTopOfInput: <boolean>false,
    indicateInvalidDate: <boolean>true,
    editableDateField: <boolean>true,
    monthSelector: <boolean>true,
    yearSelector: <boolean>true,
    disableHeaderButtons: <boolean>true,
    minYear: <number>Year.min,
    maxYear: <number>Year.max,
    componentDisabled: <boolean>false,
    showSelectorArrow: <boolean>true,
    showInputField: <boolean>true,
    openSelectorOnInputClick: <boolean>false,
    allowSelectionOnlyInCurrentMonth: <boolean>true,
    ariaLabelInputField: <string>'Date input field',
    ariaLabelClearDate: <string>'Clear Date',
    ariaLabelDecreaseDate: <string>'Decrease Date',
    ariaLabelIncreaseDate: <string>'Increase Date',
    ariaLabelOpenCalendar: <string>'Open Calendar',
    ariaLabelPrevMonth: <string>'Previous Month',
    ariaLabelNextMonth: <string>'Next Month',
    ariaLabelPrevYear: <string>'Previous Year',
    ariaLabelNextYear: <string>'Next Year'
  };

  constructor(public elem: ElementRef, private renderer: Renderer, private cdr: ChangeDetectorRef, private localeService: LocaleService, private utilService: UtilService) {
    this.setLocaleOptions();
  }


  checkNumbers(date, index) {
    let ches = date.dateObj;
    for (let i = 0; i < this.numbers.length; i++) {
      let number = this.numbers[i].dateObj;
      if ((ches.year == number.year && ches.month == number.month && ches.day == number.day) && !date.disabled) {
        return this.dates[index]['test'] = this.numbers[i].val;
      }
    }

  }

  setLocaleOptions(): void {
    let opts: IMyOptions = this.localeService.getLocaleOptions(this.locale);
    Object.keys(opts).forEach((k) => {
      (<IMyOptions>this.opts)[k] = opts[k];
    });
  }

  setOptions(): void {
    if (this.options !== undefined) {
      Object.keys(this.options).forEach((k) => {
        (<IMyOptions>this.opts)[k] = this.options[k];
      });
    }
    if (this.opts.minYear < Year.min) {
      this.opts.minYear = Year.min;
    }
    if (this.opts.maxYear > Year.max) {
      this.opts.maxYear = Year.max;
    }
    if (this.disabled !== undefined) {
      this.opts.componentDisabled = this.disabled;
    }
  }

  getSelectorTopPosition(): string {
    if (this.opts.openSelectorTopOfInput) {
      return this.elem.nativeElement.children[0].offsetHeight + 'px';
    }
  }

  resetMonthYearSelect(): void {
    this.selectMonth = false;
    this.selectYear = false;
  }

  onSelectMonthClicked(event: any): void {
    event.stopPropagation();
    this.selectMonth = !this.selectMonth;
    this.selectYear = false;
    this.cdr.detectChanges();
    if (this.selectMonth) {
      let today: IMyDate = this.getToday();
      this.months.length = 0;
      for (let i = 1; i <= 12; i += 3) {
        let row: Array<IMyCalendarMonth> = [];
        for (let j = i; j < i + 3; j++) {
          let disabled: boolean = this.utilService.isMonthDisabledByDisableUntil({
            year: this.visibleMonth.year,
            month: j,
            day: this.daysInMonth(j, this.visibleMonth.year)
          }, this.opts.disableUntil)
            || this.utilService.isMonthDisabledByDisableSince({
              year: this.visibleMonth.year,
              month: j,
              day: 1
            }, this.opts.disableSince);
          row.push({
            nbr: j,
            name: this.opts.monthLabels[j],
            currMonth: j === today.month && this.visibleMonth.year === today.year,
            selected: j === this.visibleMonth.monthNbr,
            disabled: disabled
          });
        }
        this.months.push(row);
      }
    }
  }

  onMonthCellClicked(cell: IMyCalendarMonth): void {
    let mc: boolean = cell.nbr !== this.visibleMonth.monthNbr;
    this.visibleMonth = { monthTxt: this.monthText(cell.nbr), monthNbr: cell.nbr, year: this.visibleMonth.year };
    this.generateCalendar(cell.nbr, this.visibleMonth.year, mc);
    this.selectMonth = false;
    this.selectorEl.nativeElement.focus();
  }

  onMonthCellKeyDown(event: any, cell: IMyCalendarMonth) {
    if ((event.keyCode === KeyCode.enter || event.keyCode === KeyCode.space) && !cell.disabled) {
      event.preventDefault();
      this.onMonthCellClicked(cell);
    }
  }

  onSelectYearClicked(event: any): void {
    event.stopPropagation();
    this.selectYear = !this.selectYear;
    this.selectMonth = false;
    this.cdr.detectChanges();
    if (this.selectYear) {
      this.generateYears(Number(this.visibleMonth.year));
    }
  }

  onYearCellClicked(cell: IMyCalendarYear): void {
    let yc: boolean = cell.year !== this.visibleMonth.year;
    this.visibleMonth = { monthTxt: this.visibleMonth.monthTxt, monthNbr: this.visibleMonth.monthNbr, year: cell.year };
    this.generateCalendar(this.visibleMonth.monthNbr, cell.year, yc);
    this.selectYear = false;
    this.selectorEl.nativeElement.focus();
  }

  onYearCellKeyDown(event: any, cell: IMyCalendarYear) {
    if ((event.keyCode === KeyCode.enter || event.keyCode === KeyCode.space) && !cell.disabled) {
      event.preventDefault();
      this.onYearCellClicked(cell);
    }
  }

  onPrevYears(event: any, year: number): void {
    event.stopPropagation();
    this.generateYears(Number(year) - 25);
  }

  onNextYears(event: any, year: number): void {
    event.stopPropagation();
    this.generateYears(Number(year) + 25);
  }

  generateYears(year: number): void {
    this.years.length = 0;
    let today: IMyDate = this.getToday();
    for (let i = year; i <= 20 + year; i += 5) {
      let row: Array<IMyCalendarYear> = [];
      for (let j = i; j < i + 5; j++) {
        let disabled: boolean = this.utilService.isMonthDisabledByDisableUntil({
          year: j,
          month: this.visibleMonth.monthNbr,
          day: this.daysInMonth(this.visibleMonth.monthNbr, j)
        }, this.opts.disableUntil)
          || this.utilService.isMonthDisabledByDisableSince({
            year: j,
            month: this.visibleMonth.monthNbr,
            day: 1
          }, this.opts.disableSince);
        let minMax: boolean = j < this.opts.minYear || j > this.opts.maxYear;
        row.push({
          year: j,
          currYear: j === today.year,
          selected: j === this.visibleMonth.year,
          disabled: disabled || minMax
        });
      }
      this.years.push(row);
    }
    this.prevYearsDisabled = this.years[0][0].year <= this.opts.minYear || this.utilService.isMonthDisabledByDisableUntil({
      year: this.years[0][0].year - 1,
      month: this.visibleMonth.monthNbr,
      day: this.daysInMonth(this.visibleMonth.monthNbr, this.years[0][0].year - 1)
    }, this.opts.disableUntil);
    this.nextYearsDisabled = this.years[4][4].year >= this.opts.maxYear || this.utilService.isMonthDisabledByDisableSince({
      year: this.years[4][4].year + 1,
      month: this.visibleMonth.monthNbr,
      day: 1
    }, this.opts.disableSince);
  }

  onUserDateInput(value: string): void {
    if (value.length === 0) {
      if (this.utilService.isInitializedDate(this.selectedDate)) {
        this.clearDate();
      } else {
        this.invalidInputFieldChanged(value);
      }
    } else {
      let date: IMyDate = this.utilService.isDateValid(value, this.opts.dateFormat, this.opts.minYear, this.opts.maxYear, this.opts.disableUntil, this.opts.disableSince, this.opts.disableWeekends, this.opts.disableWeekdays, this.opts.disableDays, this.opts.disableDateRanges, this.opts.monthLabels, this.opts.enableDays);
      if (this.utilService.isInitializedDate(date)) {
        if (!this.utilService.isSameDate(date, this.selectedDate)) {
          this.selectDate(date, CalToggle.CloseByDateSel);
        } else {
          this.updateDateValue(date);
        }
      } else {
        this.invalidInputFieldChanged(value);
      }
    }
  }

  onFocusInput(event: any): void {
    this.inputFocusBlur.emit({ reason: InputFocusBlur.focus, value: event.target.value });
  }

  onBlurInput(event: any): void {
    this.selectionDayTxt = event.target.value;
    this.onTouchedCb();
    this.inputFocusBlur.emit({ reason: InputFocusBlur.blur, value: event.target.value });
  }

  onCloseSelector(event: any): void {
    if (event.keyCode === KeyCode.esc && this.showSelector && !this.opts.inline) {
      this.calendarToggle.emit(CalToggle.CloseByEsc);
      this.showSelector = false;
    }
  }

  invalidInputFieldChanged(value: string): void {
    this.invalidDate = value.length > 0;
    this.inputFieldChanged.emit({ value: value, dateFormat: this.opts.dateFormat, valid: false });
    this.onChangeCb(null);
    this.onTouchedCb();
  }

  isTodayDisabled(): void {
    this.disableTodayBtn = this.utilService.isDisabledDay(this.getToday(), this.opts.minYear, this.opts.maxYear, this.opts.disableUntil, this.opts.disableSince, this.opts.disableWeekends, this.opts.disableWeekdays, this.opts.disableDays, this.opts.disableDateRanges, this.opts.enableDays);
  }

  parseOptions(): void {
    if (this.locale) {
      this.setLocaleOptions();
    }
    this.setOptions();
    let weekDays: Array<string> = this.utilService.getWeekDays();
    this.isTodayDisabled();
    this.dayIdx = weekDays.indexOf(this.opts.firstDayOfWeek);
    if (this.dayIdx !== -1) {
      let idx: number = this.dayIdx;
      for (let i = 0; i < weekDays.length; i++) {
        this.weekDays.push(this.opts.dayLabels[weekDays[idx]]);
        idx = weekDays[idx] === 'sa' ? 0 : idx + 1;
      }
    }
  }

  writeValue(value: any): void {
    if (value && (value['date'] || value['jsdate'] || value['formatted'])) {
      this.selectedDate = value['date'] ? this.parseSelectedDate(value['date']) : value['jsdate'] ? this.parseSelectedDate(this.jsDateToMyDate(value['jsdate'])) : this.parseSelectedDate(value['formatted']);
      let cvc: boolean = this.visibleMonth.year !== this.selectedDate.year || this.visibleMonth.monthNbr !== this.selectedDate.month;
      if (cvc) {
        this.visibleMonth = {
          monthTxt: this.opts.monthLabels[this.selectedDate.month],
          monthNbr: this.selectedDate.month,
          year: this.selectedDate.year
        };
        this.generateCalendar(this.selectedDate.month, this.selectedDate.year, cvc);
      }
      this.selectionDayTxt = this.utilService.formatDate(this.selectedDate, this.opts.dateFormat, this.opts.monthLabels);
    } else if (value === null || value === '') {
      this.selectedDate = { year: 0, month: 0, day: 0 };
      this.selectionDayTxt = '';
    }
    this.inputFieldChanged.emit({
      value: this.selectionDayTxt,
      dateFormat: this.opts.dateFormat,
      valid: this.selectionDayTxt.length > 0
    });
    this.invalidDate = false;
  }

  setDisabledState(disabled: boolean): void {
    this.opts.componentDisabled = disabled;
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('selector')) {
      let s: any = changes['selector'].currentValue;
      if (typeof s === 'object') {
        if (s.open) {
          this.showSelector = true;
          this.openSelector(CalToggle.Open);
        } else {
          this.showSelector = false;
          this.closeSelector(CalToggle.CloseByApi);
        }
      } else if (s > 0) {
        this.openBtnClicked();
      }
    }

    if (changes.hasOwnProperty('placeholder')) {
      this.placeholder = changes['placeholder'].currentValue;
    }

    if (changes.hasOwnProperty('locale')) {
      this.locale = changes['locale'].currentValue;
    }

    if (changes.hasOwnProperty('disabled')) {
      this.disabled = changes['disabled'].currentValue;
    }

    if (changes.hasOwnProperty('options')) {
      this.options = changes['options'].currentValue;
    }

    this.weekDays.length = 0;
    this.parseOptions();

    let dmChange: boolean = false;
    if (changes.hasOwnProperty('defaultMonth')) {
      let dm: any = changes['defaultMonth'].currentValue;
      if (typeof dm === 'object') {
        dm = dm.defMonth;
      }
      if (dm !== null && dm !== undefined && dm !== '') {
        this.selectedMonth = this.parseSelectedMonth(dm);
      } else {
        this.selectedMonth = { monthTxt: '', monthNbr: 0, year: 0 };
      }
      dmChange = true;
    }

    if (changes.hasOwnProperty('selDate')) {
      let sd: any = changes['selDate'];
      if (sd.currentValue !== null && sd.currentValue !== undefined && sd.currentValue !== '' && Object.keys(sd.currentValue).length !== 0) {
        this.selectedDate = this.parseSelectedDate(sd.currentValue);
        setTimeout(() => {
          this.onChangeCb(this.getDateModel(this.selectedDate));
        });
      } else {
        // Do not clear on init
        if (!sd.isFirstChange()) {
          this.clearDate();
        }
      }
    }
    if (this.visibleMonth.year === 0 && this.visibleMonth.monthNbr === 0 || dmChange) {
      this.setVisibleMonth();
    } else {
      this.visibleMonth.monthTxt = this.opts.monthLabels[this.visibleMonth.monthNbr];
      this.generateCalendar(this.visibleMonth.monthNbr, this.visibleMonth.year, false);
    }
  }

  removeBtnClicked(): void {
    // Remove date button clicked
    this.clearDate();
    if (this.showSelector) {
      this.calendarToggle.emit(CalToggle.CloseByCalBtn);
    }
    this.showSelector = false;
  }

  onDecreaseBtnClicked(): void {
    // Decrease date button clicked
    this.decreaseIncreaseDate(true);
  }

  onIncreaseBtnClicked(): void {
    // Increase date button clicked
    this.decreaseIncreaseDate(false);
  }

  openBtnClicked(): void {
    // Open selector button clicked
    this.showSelector = !this.showSelector;
    this.cdr.detectChanges();
    if (this.showSelector) {
      this.openSelector(CalToggle.Open);
    } else {
      this.closeSelector(CalToggle.CloseByCalBtn);
    }
  }

  openSelector(reason: number): void {
    this.globalListener = this.globalListener || this.renderer.listenGlobal('document', 'click', (event: any) => {
      if (this.showSelector && event.target && this.elem.nativeElement !== event.target && !this.elem.nativeElement.contains(event.target)) {
        this.showSelector = false;
        this.calendarToggle.emit(CalToggle.CloseByOutClick);
      }
      if (this.opts.monthSelector || this.opts.yearSelector) {
        this.resetMonthYearSelect();
      }
    });
    this.setVisibleMonth();
    this.calendarToggle.emit(reason);
  }

  closeSelector(reason: number): void {
    if (this.globalListener) {
      this.globalListener();
    }
    this.calendarToggle.emit(reason);
  }

  setVisibleMonth(): void {
    // Sets visible month of calendar
    let y: number = 0, m: number = 0;
    if (!this.utilService.isInitializedDate(this.selectedDate)) {
      if (this.selectedMonth.year === 0 && this.selectedMonth.monthNbr === 0) {
        let today: IMyDate = this.getToday();
        y = today.year;
        m = today.month;
      } else {
        y = this.selectedMonth.year;
        m = this.selectedMonth.monthNbr;
      }
    } else {
      y = this.selectedDate.year;
      m = this.selectedDate.month;
    }
    this.visibleMonth = { monthTxt: this.opts.monthLabels[m], monthNbr: m, year: y };

    // Create current month
    this.generateCalendar(m, y, true);
  }

  onPrevMonth(): void {
    // Previous month from calendar
    let d: Date = this.getDate(this.visibleMonth.year, this.visibleMonth.monthNbr, 1);
    d.setMonth(d.getMonth() - 1);

    let y: number = d.getFullYear();
    let m: number = d.getMonth() + 1;

    this.visibleMonth = { monthTxt: this.monthText(m), monthNbr: m, year: y };
    this.generateCalendar(m, y, true);
  }

  onNextMonth(): void {
    // Next month from calendar
    let d: Date = this.getDate(this.visibleMonth.year, this.visibleMonth.monthNbr, 1);
    d.setMonth(d.getMonth() + 1);

    let y: number = d.getFullYear();
    let m: number = d.getMonth() + 1;

    this.visibleMonth = { monthTxt: this.monthText(m), monthNbr: m, year: y };
    this.generateCalendar(m, y, true);
  }

  onPrevYear(): void {
    // Previous year from calendar
    this.visibleMonth.year--;
    this.generateCalendar(this.visibleMonth.monthNbr, this.visibleMonth.year, true);
  }

  onNextYear(): void {
    // Next year from calendar
    this.visibleMonth.year++;
    this.generateCalendar(this.visibleMonth.monthNbr, this.visibleMonth.year, true);
  }

  onTodayClicked(): void {
    // Today button clicked
    let today: IMyDate = this.getToday();
    this.selectDate(today, CalToggle.CloseByDateSel);
    if (this.opts.inline && today.year !== this.visibleMonth.year || today.month !== this.visibleMonth.monthNbr) {
      this.visibleMonth = { monthTxt: this.opts.monthLabels[today.month], monthNbr: today.month, year: today.year };
      this.generateCalendar(today.month, today.year, true);
    }
  }

  onCellClicked(cell: any): void {
    // Cell clicked on the calendar
    if (cell.cmo === this.prevMonthId) {
      // Previous month day
      this.onPrevMonth();
      if (!this.opts.allowSelectionOnlyInCurrentMonth) {
        this.selectDate(cell.dateObj, CalToggle.CloseByDateSel);
      }
    } else if (cell.cmo === this.currMonthId) {
      // Current month day - if date is already selected clear it
      if (this.opts.allowDeselectDate && this.utilService.isSameDate(cell.dateObj, this.selectedDate)) {
        this.clearDate();
      } else {
        this.selectDate(cell.dateObj, CalToggle.CloseByDateSel);
      }
    } else if (cell.cmo === this.nextMonthId) {
      // Next month day
      this.onNextMonth();
      if (!this.opts.allowSelectionOnlyInCurrentMonth) {
        this.selectDate(cell.dateObj, CalToggle.CloseByDateSel);
      }
    }
    this.resetMonthYearSelect();
  }

  onCellKeyDown(event: any, cell: any) {
    // Cell keyboard handling
    if ((event.keyCode === KeyCode.enter || event.keyCode === KeyCode.space) && !cell.disabled) {
      event.preventDefault();
      this.onCellClicked(cell);
    }
  }

  clearDate(): void {
    // Clears the date
    this.updateDateValue({ year: 0, month: 0, day: 0 });
    this.setFocusToInputBox();
  }

  decreaseIncreaseDate(decrease: boolean): void {
    // Decreases or increases the date depending on the parameter
    let date: IMyDate = this.selectedDate;
    if (this.utilService.isInitializedDate(date)) {
      let d: Date = this.getDate(date.year, date.month, date.day);
      d.setDate(decrease ? d.getDate() - 1 : d.getDate() + 1);
      date = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
    } else {
      date = this.getToday();
    }
    this.selectDate(date, CalToggle.CloseByCalBtn);
  }

  selectDate(date: IMyDate, closeReason: number): void {
    this.updateDateValue(date);
    if (this.showSelector) {
      this.calendarToggle.emit(closeReason);
    }
    this.showSelector = false;
    this.setFocusToInputBox();
  }

  setFocusToInputBox(): void {
    if (!this.opts.inline && this.opts.showInputField) {
      setTimeout(() => {
        this.inputBoxEl.nativeElement.focus();
      }, 100);
    }
  }

  updateDateValue(date: IMyDate): void {
    let clear: boolean = !this.utilService.isInitializedDate(date);

    this.selectedDate = date;
    this.emitDateChanged(date);

    if (!this.opts.inline) {
      this.selectionDayTxt = clear ? '' : this.utilService.formatDate(date, this.opts.dateFormat, this.opts.monthLabels);
      this.inputFieldChanged.emit({ value: this.selectionDayTxt, dateFormat: this.opts.dateFormat, valid: !clear });
      this.invalidDate = false;
    }
  }

  emitDateChanged(date: IMyDate): void {
    if (this.utilService.isInitializedDate(date)) {
      let dateModel: IMyDateModel = this.getDateModel(date);
      this.dateChanged.emit(dateModel);
      this.onChangeCb(dateModel);
      this.onTouchedCb();
    } else {
      this.dateChanged.emit({ date: date, jsdate: null, formatted: '', epoc: 0 });
      this.onChangeCb(null);
      this.onTouchedCb();
    }
  }

  getDateModel(date: IMyDate): IMyDateModel {
    // Creates a date model object from the given parameter
    return {
      date: date,
      jsdate: this.getDate(date.year, date.month, date.day),
      formatted: this.utilService.formatDate(date, this.opts.dateFormat, this.opts.monthLabels),
      epoc: Math.round(this.getTimeInMilliseconds(date) / 1000.0)
    };
  }

  monthText(m: number): string {
    // Returns month as a text
    return this.opts.monthLabels[m];
  }

  monthStartIdx(y: number, m: number): number {
    // Month start index
    let d = new Date();
    d.setDate(1);
    d.setMonth(m - 1);
    d.setFullYear(y);
    let idx = d.getDay() + this.sundayIdx();
    return idx >= 7 ? idx - 7 : idx;
  }

  daysInMonth(m: number, y: number): number {
    // Return number of days of current month
    return new Date(y, m, 0).getDate();
  }

  daysInPrevMonth(m: number, y: number): number {
    // Return number of days of the previous month
    let d: Date = this.getDate(y, m, 1);
    d.setMonth(d.getMonth() - 1);
    return this.daysInMonth(d.getMonth() + 1, d.getFullYear());
  }

  isCurrDay(d: number, m: number, y: number, cmo: number, today: IMyDate): boolean {
    // Check is a given date the today
    return d === today.day && m === today.month && y === today.year && cmo === this.currMonthId;
  }

  getToday(): IMyDate {
    let date: Date = new Date();
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  getTimeInMilliseconds(date: IMyDate): number {
    return this.getDate(date.year, date.month, date.day).getTime();
  }

  getWeekday(date: IMyDate): string {
    // Get weekday: su, mo, tu, we ...
    let weekDays: Array<string> = this.utilService.getWeekDays();
    return weekDays[this.utilService.getDayNumber(date)];
  }

  getDate(year: number, month: number, day: number): Date {
    // Creates a date object from given year, month and day
    return new Date(year, month - 1, day, 0, 0, 0, 0);
  }

  sundayIdx(): number {
    // Index of Sunday day
    return this.dayIdx > 0 ? 7 - this.dayIdx : 0;
  }

  generateCalendar(m: number, y: number, notifyChange: boolean): void {
    this.dates.length = 0;
    let today: IMyDate = this.getToday();
    let monthStart: number = this.monthStartIdx(y, m);
    let dInThisM: number = this.daysInMonth(m, y);
    let dInPrevM: number = this.daysInPrevMonth(m, y);


    let dayNbr: number = 1;
    let cmo: number = this.prevMonthId;
    for (let i = 1; i < 7; i++) {
      let week: Array<IMyCalendarDay> = [];
      if (i === 1) {
        // First week
        let pm = dInPrevM - monthStart + 1;
        // Previous month
        for (let j = pm; j <= dInPrevM; j++) {
          let date: IMyDate = { year: m === 1 ? y - 1 : y, month: m === 1 ? 12 : m - 1, day: j };
          week.push({
            dateObj: date, cmo: cmo, currDay: this.isCurrDay(j, m, y, cmo, today),
            disabled: false, //this.utilService.isDisabledDay(date, this.opts.minYear, this.opts.maxYear, this.opts.disableUntil, this.opts.disableSince, this.opts.disableWeekends, this.opts.disableWeekdays, this.opts.disableDays, this.opts.disableDateRanges, this.opts.enableDays),
            markedDate: this.utilService.isMarkedDate(date, this.opts.markDates, this.opts.markWeekends),
            highlight: this.utilService.isHighlightedDate(date, this.opts.sunHighlight, this.opts.satHighlight, this.opts.highlightDates)
          });
        }

        cmo = this.currMonthId;
        // Current month
        let daysLeft: number = 7 - week.length;
        for (let j = 0; j < daysLeft; j++) {
          let date: IMyDate = { year: y, month: m, day: dayNbr };
          week.push({
            dateObj: date, cmo: cmo, currDay: this.isCurrDay(dayNbr, m, y, cmo, today),
            disabled: false, // this.utilService.isDisabledDay(date, this.opts.minYear, this.opts.maxYear, this.opts.disableUntil, this.opts.disableSince, this.opts.disableWeekends, this.opts.disableWeekdays, this.opts.disableDays, this.opts.disableDateRanges, this.opts.enableDays),
            markedDate: this.utilService.isMarkedDate(date, this.opts.markDates, this.opts.markWeekends),
            highlight: this.utilService.isHighlightedDate(date, this.opts.sunHighlight, this.opts.satHighlight, this.opts.highlightDates)
          });
          dayNbr++;
        }
      } else {

        // Rest of the weeks
        for (let j = 1; j < 8; j++) {
          if (dayNbr > dInThisM) {
            // Next month
            dayNbr = 1;
            cmo = this.nextMonthId;
          }
          let date: IMyDate = {
            year: cmo === this.nextMonthId && m === 12 ? y + 1 : y,
            month: cmo === this.currMonthId ? m : cmo === this.nextMonthId && m < 12 ? m + 1 : 1,
            day: dayNbr
          };
          week.push({
            dateObj: date, cmo: cmo, currDay: this.isCurrDay(dayNbr, m, y, cmo, today),
            disabled: false, // this.utilService.isDisabledDay(date, this.opts.minYear, this.opts.maxYear, this.opts.disableUntil, this.opts.disableSince, this.opts.disableWeekends, this.opts.disableWeekdays, this.opts.disableDays, this.opts.disableDateRanges, this.opts.enableDays),
            markedDate: this.utilService.isMarkedDate(date, this.opts.markDates, this.opts.markWeekends),
            highlight: this.utilService.isHighlightedDate(date, this.opts.sunHighlight, this.opts.satHighlight, this.opts.highlightDates)
          });
          dayNbr++;


        }
      }
      let weekNbr: number = this.opts.showWeekNumbers && this.opts.firstDayOfWeek === 'mo' ? this.utilService.getWeekNumber(week[0].dateObj) : 0;
      this.dates.push({ week: week, weekNbr: weekNbr });
    }

    // this.setHeaderBtnDisabledState(m, y);

    if (notifyChange) {
      // Notify parent
      this.calendarViewChanged.emit({
        year: y,
        month: m,
        first: { number: 1, weekday: this.getWeekday({ year: y, month: m, day: 1 }) },
        last: { number: dInThisM, weekday: this.getWeekday({ year: y, month: m, day: dInThisM }) }
      });
    }
  }

  parseSelectedDate(selDate: any): IMyDate {
    // Parse date value - it can be string or IMyDate object
    let date: IMyDate = { day: 0, month: 0, year: 0 };
    if (typeof selDate === 'string') {
      let sd: string = <string>selDate;
      let df: string = this.opts.dateFormat;

      let delimeters: Array<string> = this.utilService.getDateFormatDelimeters(df);
      let dateValue: Array<IMyDateFormat> = this.utilService.getDateValue(sd, df, delimeters);
      date.year = this.utilService.getNumberByValue(dateValue[0]);
      date.month = df.indexOf(MMM) !== -1 ? this.utilService.getMonthNumberByMonthName(dateValue[1], this.opts.monthLabels) : this.utilService.getNumberByValue(dateValue[1]);
      date.day = this.utilService.getNumberByValue(dateValue[2]);
    } else if (typeof selDate === 'object') {
      date = selDate;
    }
    this.selectionDayTxt = this.utilService.formatDate(date, this.opts.dateFormat, this.opts.monthLabels);
    return date;
  }

  jsDateToMyDate(date: Date): IMyDate {
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  parseSelectedMonth(ms: string): IMyMonth {
    return this.utilService.parseDefaultMonth(ms);
  }

  setHeaderBtnDisabledState(m: number, y: number): void {
    let dpm: boolean = false;
    let dpy: boolean = false;
    let dnm: boolean = false;
    let dny: boolean = false;
    if (this.opts.disableHeaderButtons) {
      dpm = this.utilService.isMonthDisabledByDisableUntil({
        year: m === 1 ? y - 1 : y,
        month: m === 1 ? 12 : m - 1,
        day: this.daysInMonth(m === 1 ? 12 : m - 1, m === 1 ? y - 1 : y)
      }, this.opts.disableUntil);
      dpy = this.utilService.isMonthDisabledByDisableUntil({
        year: y - 1,
        month: m,
        day: this.daysInMonth(m, y - 1)
      }, this.opts.disableUntil);
      dnm = this.utilService.isMonthDisabledByDisableSince({
        year: m === 12 ? y + 1 : y,
        month: m === 12 ? 1 : m + 1,
        day: 1
      }, this.opts.disableSince);
      dny = this.utilService.isMonthDisabledByDisableSince({ year: y + 1, month: m, day: 1 }, this.opts.disableSince);
    }
    this.prevMonthDisabled = m === 1 && y === this.opts.minYear || dpm;
    this.prevYearDisabled = y - 1 < this.opts.minYear || dpy;
    this.nextMonthDisabled = m === 12 && y === this.opts.maxYear || dnm;
    this.nextYearDisabled = y + 1 > this.opts.maxYear || dny;
  }

  // Remove listeners or nullify globals on component destroy
  ngOnDestroy() {
    if (this.globalListener) {
      this.globalListener();
    }
  }
}
