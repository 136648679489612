import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  locationData: Array<any> = null;

  private locationSource = new BehaviorSubject(this.locationData);
  getLocation = this.locationSource.asObservable();

  constructor() { }

  addLocation(location: Array<any>) {
    this.locationSource.next(location)
  }

  updateLocationStatus(locationID, status){
    const currentValue = this.locationSource.value;
    const locationIndex = currentValue.findIndex(x => x.id === locationID);
    currentValue[locationIndex].online = status;
    this.locationSource.next(currentValue);
  }

}
