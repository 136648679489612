export class TicketConfiguration {
  id = '';
  created_at = 1594202143;
  updated_at = 1594202143;
  price = 0;
  name = '';
}
export class Attraction {
  id = '';
  created_at = 1594202143;
  updated_at = 1594202143;
  name = 'Test 1';
  permalink = 'test-1';
  online = true;
  ticket_configurations = new Array<Object>();
  address: string;
  category: string;
}
