import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { NotifierService } from "angular-notifier";
import { Observable, of } from "rxjs";
import { CONFIG } from "../config/config";
import { catchError, map, take } from "rxjs/operators";
import { DISCOUNT_TYPOLOGY } from "../admin/promo-discount/promo-discount.component";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  private headers: HttpHeaders;
  notifier: NotifierService;

  constructor(
    private _http: HttpClient,
    private _notifierService: NotifierService
  ) {
    this.notifier = this._notifierService;
    this.headers = new HttpHeaders().set("Content-Type", "application/json");
  }

  // == Locations
  getLocations(): Observable<any> {
    return this._http.get(CONFIG.API_URL + "admin/locations").pipe(
      map((res: any) => {
        return {
          success: true,
          data: res,
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify("error", error.error.errors[0]);
        } else {
          this.notifier.notify("error", "Si è verificato un errore!");
        }
        return of(result as T);
      })
    );
  }

  updateLocationStatus(locationID: string, data): Observable<any> {
    return this._http
      .put(CONFIG.API_URL + "admin/locations/" + locationID, data)
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  //Dashboard
  getDashboardData(date): Observable<any> {
    const params = new HttpParams()
      .set("month", date.month)
      .set("year", date.year);

    return this._http.get(CONFIG.API_URL + "admin/dashboard", { params }).pipe(
      map((res: any) => {
        return {
          success: true,
          data: res,
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify("error", error.error.errors[0]);
        } else {
          this.notifier.notify("error", "Si è verificato un errore!");
        }
        return of(result as T);
      })
    );
  }

  // == Config Closures
  getClosures(locationId: string): Observable<any> {
    return this._http
      .get(CONFIG.API_URL + "admin/locations/" + locationId + "/closings")
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  deleteKalataClosure(clID: string, locationID: string): Observable<any> {
    return this._http.delete(CONFIG.API_URL + "admin/locations/" + locationID + "/closings/" + clID).pipe(
      map((res: any) => {
        return {
          success: true,
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify("error", error.error.errors[0]);
        } else {
          this.notifier.notify("error", "Si è verificato un errore!");
        }
        return of(result as T);
      })
    );
  }

  deleteClosure(clID: string, locationID: string): Observable<any> {
    return this._http.delete(CONFIG.API_URL + "admin/closings/" + clID).pipe(
      map((res: any) => {
        return {
          success: true,
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify("error", error.error.errors[0]);
        } else {
          this.notifier.notify("error", "Si è verificato un errore!");
        }
        return of(result as T);
      })
    );
  }

  addKalataClosure(data, locationID): Observable<any> {
    return this._http.post(CONFIG.API_URL + "admin/locations/" + locationID + "/closings", data).pipe(
      map((res: any) => {
        console.log(res)
        return {
          success: true,
          data: res,
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify("error", error.error.errors[0]);
        } else {
          this.notifier.notify("error", "Si è verificato un errore!");
        }
        return of(result as T);
      })
    );
  }

  addClosure(data, locationID): Observable<any> {
    return this._http.post(CONFIG.API_URL + "admin/closings", data).pipe(
      map((res: any) => {
        console.log(res)
        return {
          success: true,
          data: res,
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify("error", error.error.errors[0]);
        } else {
          this.notifier.notify("error", "Si è verificato un errore!");
        }
        return of(result as T);
      })
    );
  }
  updateClosure(data): Observable<any> {
    return this._http
      .put(CONFIG.API_URL + "admin/closings/" + data.closing.id, data)
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  // == Config Open Date
  getConfigByLocation(locationID: string): Observable<any> {
    return this._http
      .get(CONFIG.API_URL + "admin/locations/" + locationID + "/configurations")
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  getSingleConfig(locationID: string, configID: string): Observable<any> {
    return this._http
      .get(
        CONFIG.API_URL +
          "admin/locations/" +
          locationID +
          "/configurations/" +
          configID
      )
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  updateCourseConfig(
    courseConfigID: string,
    configID: string,
    data: object
  ): Observable<any> {
    return this._http
      .put(
        CONFIG.API_URL +
          "admin/configurations/" +
          configID +
          "/course_configurations/" +
          courseConfigID,
        data
      )
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  updateConfigName(
    locationID: string,
    configID: string,
    data: object
  ): Observable<any> {
    return this._http
      .put(
        CONFIG.API_URL +
          "admin/locations/" +
          locationID +
          "/configurations/" +
          configID,
        data
      )
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  openNewDate(
    locationID: string,
    configID: string,
    data: object
  ): Observable<any> {
    return this._http
      .post(
        CONFIG.API_URL +
          "admin/locations/" +
          locationID +
          "/configurations/" +
          configID +
          "/apply",
        data
      )
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  // == Config Calendar
  getCalendar(locationID: string, date: string): Observable<any> {
    const params = new HttpParams().set("date", date);

    return this._http
      .get(CONFIG.API_URL + "admin/locations/" + locationID + "/calendar", {
        params,
      })
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  getConfigDataCalendar(locationID: string, date: string): Observable<any> {
    const params = new HttpParams().set("date", date);

    return this._http
      .get(
        CONFIG.API_URL +
          "admin/locations/" +
          locationID +
          "/calendar/configuration",
        { params }
      )
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  updateCalendarCourse(
    locationID: string,
    courseID: string,
    data
  ): Observable<any> {
    return this._http
      .put(
        CONFIG.API_URL +
          "admin/locations/" +
          locationID +
          "/courses/" +
          courseID,
        data
      )
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  deleteCalendarCourse(locationID: string, courseID: string): Observable<any> {
    return this._http
      .delete(
        CONFIG.API_URL +
          "admin/locations/" +
          locationID +
          "/courses/" +
          courseID
      )
      .pipe(
        map((res: any) => {
          return {
            success: true,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  addCalendarTicket(courseID: string, data: object): Observable<any> {
    return this._http
      .post(CONFIG.API_URL + "admin/courses/" + courseID + "/tickets", data)
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  updateCalendarTicket(
    courseID: string,
    ticketID: string,
    data: object
  ): Observable<any> {
    return this._http
      .put(
        CONFIG.API_URL + "admin/courses/" + courseID + "/tickets/" + ticketID,
        data
      )
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  deleteCalendarTicket(courseID: string, ticketID: string): Observable<any> {
    return this._http
      .delete(
        CONFIG.API_URL + "admin/courses/" + courseID + "/tickets/" + ticketID
      )
      .pipe(
        map((res: any) => {
          return {
            success: true,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  addCalendarTimeSlot(courseID: string, data: object): Observable<any> {
    return this._http
      .post(CONFIG.API_URL + "admin/courses/" + courseID + "/time_slots", data)
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  updateCalendarTimeSlot(
    courseID: string,
    timeSlotID: string,
    data: object
  ): Observable<any> {
    return this._http
      .put(
        CONFIG.API_URL +
          "admin/courses/" +
          courseID +
          "/time_slots/" +
          timeSlotID,
        data
      )
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  deleteCalendarTimeSlot(
    courseID: string,
    timeSlotID: string
  ): Observable<any> {
    return this._http
      .delete(
        CONFIG.API_URL +
          "admin/courses/" +
          courseID +
          "/time_slots/" +
          timeSlotID
      )
      .pipe(
        map((res: any) => {
          return {
            success: true,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  // - Ticket
  addNewTicketConfiguration(
    courseConfigID: string,
    data: object
  ): Observable<any> {
    return this._http
      .post(
        CONFIG.API_URL +
          "admin/course_configurations/" +
          courseConfigID +
          "/ticket_configurations",
        data
      )
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  deleteTicketConfiguration(
    courseConfigID: string,
    ticketConfigID: string
  ): Observable<any> {
    return this._http
      .delete(
        CONFIG.API_URL +
          "admin/course_configurations/" +
          courseConfigID +
          "/ticket_configurations/" +
          ticketConfigID
      )
      .pipe(
        map((res: any) => {
          return {
            success: true,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  updateTicketConfiguration(
    courseConfigID: string,
    ticketConfigID: string,
    data: object
  ): Observable<any> {
    return this._http
      .put(
        CONFIG.API_URL +
          "admin/course_configurations/" +
          courseConfigID +
          "/ticket_configurations/" +
          ticketConfigID,
        data
      )
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  // - TimeTable
  addNewTimeSlotConfiguration(
    courseConfigID: string,
    data: object
  ): Observable<any> {
    return this._http
      .post(
        CONFIG.API_URL +
          "admin/course_configurations/" +
          courseConfigID +
          "/time_slot_configurations",
        data
      )
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  deleteTimeTableConfiguration(
    courseConfigID: string,
    timeConfigID: string
  ): Observable<any> {
    return this._http
      .delete(
        CONFIG.API_URL +
          "admin/course_configurations/" +
          courseConfigID +
          "/time_slot_configurations/" +
          timeConfigID
      )
      .pipe(
        map((res: any) => {
          return {
            success: true,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  updateTimeSlot(
    courseConfigID: string,
    timeConfigID: string,
    data
  ): Observable<any> {
    return this._http
      .put(
        CONFIG.API_URL +
          "admin/course_configurations/" +
          courseConfigID +
          "/time_slot_configurations/" +
          timeConfigID,
        data
      )
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  // == Orders
  getOrders(locationID: string, params: Array<string> = []): Observable<any> {
    var url = `${CONFIG.API_URL}admin/locations/${locationID}/orders${
      params && params.length > 0 ? `?${params.join("&")}` : ""
    }`;
    return this._http.get(url).pipe(
      map((res: any) => {
        return {
          success: true,
          data: res,
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify("error", error.error.errors[0]);
        } else {
          this.notifier.notify("error", "Si è verificato un errore!");
        }
        return of(result as T);
      })
    );
  }

  // == Promotions
  getPromotions(typology: DISCOUNT_TYPOLOGY): Observable<any> {
    return this._http
      .get(`${CONFIG.API_URL}admin/promotions?typology=${typology}`)
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  deletePromotion(promoID: string): Observable<any> {
    return this._http
      .delete(CONFIG.API_URL + "admin/promotions/" + promoID)
      .pipe(
        map((res: any) => {
          return {
            success: true,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  addPromotion(promoData: object): Observable<any> {
    return this._http
      .post(CONFIG.API_URL + "admin/promotions/", promoData)
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  // == Banners
  getBanners(): Observable<any> {
    return this._http.get(CONFIG.API_URL + "admin/banners").pipe(
      take(1),
      map((res: any) => {
        return {
          success: true,
          data: res,
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify("error", error.error.errors[0]);
        } else {
          this.notifier.notify("error", "Si è verificato un errore!");
        }
        return of(result as T);
      })
    );
  }

  updateBanner(data, bannerID): Observable<any> {
    return this._http
      .put(CONFIG.API_URL + "admin/banners/" + bannerID, data)
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  // == Upload

  downloadCsv(locationID: string, params: Array<string> = []) {
    var url = `${
      CONFIG.API_URL
    }admin/locations/${locationID}/orders?format=csv${
      params && params.length > 0 ? `&${params.join("&")}` : ""
    }`;
    return this._http.get(url, { responseType: "blob" }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify("error", error.error.errors[0]);
        } else {
          this.notifier.notify("error", "Si è verificato un errore!");
        }
        return of(result as T);
      })
    );
  }
}
