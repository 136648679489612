import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IMyDpOptions } from 'src/my-date-picker';
import { AdminService } from 'src/app/services/admin.service';
import { SharedService } from 'src/app/services/shared.service';
import { NotifierService } from 'angular-notifier';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import * as moment from 'moment';
import { AttrazioniService } from 'src/app/services/attrazioni.service';
import { ChiusureService } from 'src/app/services/chiusure.service';
import { Chiusure } from 'src/app/Models/Chiusure.model';
import { throwIfEmpty } from 'rxjs/operators';

@Component({
  selector: 'app-chiusure',
  templateUrl: './chiusure.component.html',
  styleUrls: ['./chiusure.component.scss']
})
export class ChiusureComponent implements OnInit {

  locations: Array<any> = [];
  currentLocation: string;
  selectedLocation: { id: string, name: string } = {id: '', name: ''};

  selectedLocationClosign: { id: string, name: string } = {id: '', name: ''};

  closuresData: Array<Chiusure> = [];

  isLoading: boolean = false;

  isAddLoading: boolean = false;

  isSubmitted: boolean = false;

  private subscriptionTwo$: Subscription;

  closuresForm: FormGroup;

  private date: Date = new Date();

  fromDate: any = {year: 0, month: 0, day: 0};
  toDate: any = {year: 0, month: 0, day: 0};

  myDatePickerInlineOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    minYear: this.date.getFullYear() - 1,
    maxYear: this.date.getDate(),
    monthLabels: {
      1: 'Gennaio',
      2: 'Febbraio',
      3: 'Marzo',
      4: 'Aprile',
      5: 'Maggio',
      6: 'Giugno',
      7: 'Luglio',
      8: 'Agosto',
      9: 'Settembre',
      10: 'Ottobre',
      11: 'Novembre',
      12: 'Dicembre'
    },
    disableUntil: {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() - 1},
    disableDays: [{year: 0, month: 0, day: 0}],
    // disableSince: {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() + 1},
    selectorHeight: 'auto',
    selectorWidth: '410px',
    allowSelectionOnlyInCurrentMonth: false,
    disableHeaderButtons: true,
    yearSelector: false,
    monthSelector: false,
    showTodayBtn: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: false,
    editableDateField: false,
    showSelectorArrow: false,
    inline: false
  };

  editing: boolean = false;
  localeIt: any = 'it';
  editClosureId: string;
  tmpClosure;

  // *******************
  current_location = '';
  constructor(
    private _adminService: AdminService,
    private _sharedService: SharedService,
    private notifierService: NotifierService,
    private formBuilder: FormBuilder,
    public ngxSmartModalService: NgxSmartModalService,
    private attractionService: AttrazioniService,
    private chiusureService: ChiusureService,
    private ref: ChangeDetectorRef) {
    this.fromDate = {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate()};
    this.toDate = {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate()};
  }

  ngOnInit() {
    this.attractionService.getAttractions().subscribe((resp: any) => {
      if (resp && resp.success) {
        this.locations = resp.data.attractions;
        const loc = localStorage.getItem('current_location_revelia');
        if (loc) {
          this.current_location = loc;
        } else {
          this.current_location = this.locations[0].id;
        }
      this.selectedLocation = {
        id: this.current_location,
        name: this.locations[0].name
      };
      this.closuresForm.controls.attraction.setValue(this.current_location);

      // get closures first init
      this.getClosuresApi(this.current_location);
      // this.getConfigData();
    }
  });
    // == Form Builder
    this.closuresForm = this.formBuilder.group({
      attraction: ['', Validators.required],
      to_date: [this.fromDate, Validators.required],
      from_date: [this.toDate, Validators.required],
      description: '',
    });
  }

  edit(closure: Chiusure) {
    this.editing = true;
    this.editClosureId = closure.id;
    this.tmpClosure = closure;
    this.closuresForm.controls.attraction.setValue(closure.attraction.id);
    this.closuresForm.controls.to_date.setValue(closure.to_date);
    this.closuresForm.controls.from_date.setValue(closure.from_date);
    this.closuresForm.controls.description.setValue(closure.description);
    let to_date = closure.to_date.split('-');
    let from_date = closure.from_date.split('-');

    this.toDate = {year: to_date[0], month: Number(to_date[1]), day: to_date[2]};
    this.fromDate = {year: from_date[0], month: Number(from_date[1]), day: from_date[2]};
    this.ref.detectChanges();
    this.openClosureModal();
  }
  changeLocation(ev) {

    this.selectedLocation = {
      id: ev.target.value,
      name: ev.target.options[ev.target.selectedIndex].getAttribute('name')
    };
    localStorage.setItem('current_location_revelia', ev.target.value);
  //  this.changeLocationClosing(null);

    this.getClosuresApi(ev.target.value);
  }

  changeLocationClosing(ev) {
    let current_location: any;
    current_location = localStorage.getItem('current_location_revelia');
    if(current_location){
      this.selectedLocationClosign = current_location;
      this.closureControls.location.setValue(current_location, {
        onlySelf: true
      });
    }
    else{
      this.selectedLocationClosign = {
        id: ev.target.value,
        name: ev.target.options[ev.target.selectedIndex].getAttribute('name')
      };
      // this.closureControls.location.setValue(ev.target.value, {
      //   onlySelf: true
      // });
    }
  }

  deleteClosure(closureID: string, locationID: string, index) {
    if (!closureID || closureID === '' || !locationID || locationID === '') {
      this.notifierService.notify('error', 'ID non presente!');
      return;
    }
    this._adminService.deleteClosure(closureID, locationID).subscribe(res => {
      if (res.success) {
        this.closuresData.splice(index, 1);
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }
    });
  }

  // New Closure
  get closureControls() {
    return this.closuresForm.controls;
  }

  insertClosure(){
    this.editing = false;
    this.openClosureModal();
  }

  openClosureModal() {
    this.ngxSmartModalService.setModalData({}, 'configClosuresModal');
    this.ngxSmartModalService.open('configClosuresModal');
  }

  addClosure() {

    if (!this.closuresForm.valid) {
      return false;
    }

    // create post data _obj
    const formValue = this.closuresForm.value;

    this.isSubmitted = this.isAddLoading = true;

    let data = {

      closing: {
        attraction_id:formValue.attraction,
        from_date: moment.unix(formValue.from_date.epoc).format('YYYY-MM-DD'),
        to_date: moment.unix(formValue.to_date.epoc).format('YYYY-MM-DD'),
        description: formValue.description,
        // public_only: formValue.public_only
      }
    };

    this._adminService.addClosure(data, formValue.location).subscribe(res => {
      this.isAddLoading = false;
      this.getClosuresApi(this.current_location);
      this.closuresForm = this.formBuilder.group({
        attraction: ['', Validators.required],
        to_date: [this.toDate, Validators.required],
        from_date: [this.fromDate, Validators.required],
        description: '',
      });
      this.ref.detectChanges();
      this.ngxSmartModalService.close('configClosuresModal');
    });

  }

  editClosure(){
    if (!this.closuresForm.valid) {
      return false;
    }

    // create post data _obj
    const formValue = this.closuresForm.value;

    this.isSubmitted = this.isAddLoading = true;

    let from_tmp;
    if(!formValue.from_date.epoc){
      from_tmp = formValue.from_date;
    }else{
      from_tmp = moment.unix(formValue.from_date.epoc).format('YYYY-MM-DD');
    }

    let to_tmp;
    if(!formValue.to_date.epoc){
      to_tmp = formValue.to_date;
    }else{
      to_tmp = moment.unix(formValue.to_date.epoc).format('YYYY-MM-DD');
    }

    let data = {

      closing: {
        id: this.editClosureId,
        attraction_id:formValue.attraction,
        from_date: from_tmp,
        to_date: to_tmp,
        description: formValue.description,
        // public_only: formValue.public_only
      }
    };

    this._adminService.updateClosure(data).subscribe(res => {
      this.isAddLoading = false;
      this.editing = false;
      this.closuresForm = this.formBuilder.group({
        attraction: ['', Validators.required],
        to_date: [this.toDate, Validators.required],
        from_date: [this.fromDate, Validators.required],
        description: '',
      });
      this.ref.detectChanges();
      this.getClosuresApi(this.current_location);
      this.ngxSmartModalService.close('configClosuresModal');
    });
  }

  /**
   *
   * @param locationId
   */
  private getClosuresApi(locationId: string) {
    this.isLoading = true;
    this.subscriptionTwo$ = this.chiusureService.getChiusures(locationId).subscribe(res => {
      this.closuresData = res.success ? res.data.closings : null;
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.subscriptionTwo$.unsubscribe();
  }

}
