import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChildren} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {SharedService} from '../../services/shared.service';
import {NotifierService} from 'angular-notifier';
import {FormBuilder} from '@angular/forms';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {IMyDateModel, IMyDpOptions} from '../../../my-date-picker/interfaces';
import * as jQuery from 'jquery';
import {isInt} from '../../helpers/helpers';

@Component({
  selector: 'app-config-calendar',
  templateUrl: './config-calendar.component.html',
  styleUrls: ['./config-calendar.component.scss']
})
export class ConfigCalendarComponent implements OnInit, OnDestroy {

  date: Date = new Date();

  locations: Array<any> = [];

  selectedLocation: { id: string, name: string } = {id: '', name: ''};

  selectedDate: string = moment().format('YYYY-MM-DD');

  isLoading: boolean = false;

  private $subscriptionCalendar: Subscription;

  calendarData: any = null;

  myDatePickerInlineOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
   minYear: this.date.getFullYear(),
    maxYear: this.date.getFullYear(),
    inline: true,
    monthLabels: {
      1: 'Gennaio',
      2: 'Febbraio',
      3: 'Marzo',
      4: 'Aprile',
      5: 'Maggio',
      6: 'Giugno',
      7: 'Luglio',
      8: 'Agosto',
      9: 'Settembre',
      10: 'Ottobre',
      11: 'Novembre',
      12: 'Dicembre'
    },
    disableDays: [{year: 0, month: 0, day: 0}],
    highlightDates: [{year: 0, month: 0, day: 0}],
    selectorHeight: 'auto',
    selectorWidth: 'auto',
    allowSelectionOnlyInCurrentMonth: false,
    disableHeaderButtons: true,
    yearSelector: false,
    monthSelector: false,
    showTodayBtn: false,
    sunHighlight: false,
  };

  tickets;
  localeIt: any = 'it';

  todayDate: { year: number, month: number, day: number } = {year: 0, month: 0, day: 0};

  numbers: Array<object> = [];

  //config list
  configCoursesArray: Array<any> = [];

  selectedCourseField: any = null;
  selectedDescField:any = null;

  weekDayNumber: number;

  weekDayLabel: string;

  current_location: string;

  @ViewChildren('editForm') editForm;

  constructor(
    private _adminService: AdminService,
    private _sharedService: SharedService,
    private notifierService: NotifierService,
    private ref: ChangeDetectorRef
  ) {
    this.todayDate = {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate()};
  }

  ngOnInit() {

    this.weekDayNumber = moment(this.selectedDate).day();
    this.weekDayLabel = this.getWeekDayName(this.selectedDate).toUpperCase();

    // == Get Locations
    this._sharedService.getLocation.subscribe(location => {
      let loc = localStorage.getItem('current_location');
      this.locations = location;
      if(loc){
        this.current_location = loc;
      }else{
        this.current_location = this.locations[0].id;
      }

      let current = this.locations.find(x => x.id === this.current_location)

      this.selectedLocation = {
        id: current.id,
        name: current.name
      };

      this.getConfigurationsAPI(this.selectedLocation.id);
      // get closures first init
      this.getCalendarApi(this.selectedLocation.id);

    });
    
    // == Get Configuration list
    this.getConfigData();
  }

  changeLocation(ev) {
    this.selectedLocation = {
      id: ev.target.value,
      name: ev.target.options[ev.target.selectedIndex].getAttribute('name')
    };
    localStorage.setItem('current_location', ev.target.value);
    //update calendar Data => Location
    this.getCalendarApi(ev.target.value);

    //update config Data => Location
    this.getConfigData();
  }

  onDateChanged(event: IMyDateModel) {

    this.selectedDate = event.formatted;

    this.weekDayNumber = moment(event.formatted).day();
    this.weekDayLabel = this.getWeekDayName(event.formatted).toUpperCase();

    //update calendar Data => Date
    this.getCalendarApi(this.selectedLocation.id);

    //update config Data => Date
    this.getConfigData();
  }

  openEditRowEv(ev, courseIndex, ticket, ticketIndex) {
    let parent = ev.target.parentElement.parentElement.parentElement.parentElement;

    if (ev.target.classList.contains('fas')) {
      parent = ev.target.parentElement.parentElement.parentElement.parentElement.parentElement;
    }

    if (!parent.classList.contains('open-edit-row')) {
      parent.classList.toggle('open-edit-row');
    }

    const editFORM = this.editForm._results[courseIndex];

    //fill form rows
    editFORM.controls.name.setValue(ticket.name);
    editFORM.controls.position.setValue(ticket.position);
    editFORM.controls.description.setValue(ticket.description);
    editFORM.controls.price.setValue(ticket.price);
    editFORM.controls.ticketID.setValue(ticket.id);
    editFORM.controls.ticketIndex.setValue(ticketIndex);
  }

  closeEditRow(ev, courseIndex) {
    let parent = ev.target.parentElement.parentElement.parentElement.parentElement;

    if (ev.target.classList.contains('fas')) {
      parent = ev.target.parentElement.parentElement.parentElement.parentElement.parentElement;
    }

    parent.classList.remove('open-edit-row');

    //reset edit form
    this.editForm._results[courseIndex].reset();

  }

  openNewRowEv(ev, courseIndex) {
    ev.target.parentElement.parentElement.parentElement.parentElement.classList.toggle('open-new-row');;
  }


  /**
   *
   * @param locationId
   */
  private getCalendarApi(locationId: string): void {
    this.isLoading = true;
    this.$subscriptionCalendar = this._adminService.getCalendar(locationId, this.selectedDate)
      .subscribe(res => {
        this.isLoading = false;
        if (!res.success) {
          return;
        }
        this.calendarData = res.data;
        this.disableDays(this.calendarData.calendar.disabled_dates);
        this.reservedDates(this.calendarData.calendar.opening_dates);
        this.dateCounters(this.calendarData.reservations_counters);
      });
  }

  private getConfigData(): void {
    this.isLoading = true;
    this._adminService.getConfigDataCalendar(this.selectedLocation.id, this.selectedDate).subscribe(res => {
      this.isLoading = false;
      if (res.success) {
        this.configCoursesArray = res.data.course_configurations;
      }
    });
  }


  //Courses
  editCourseField(field, type) {
    this.selectedCourseField = {
      field,
      type
    };
  }

  editDescField(field, type) {
    this.selectedDescField = {
      field,
      type
    };
  }

  coursePublicEvent(event, courseID) {
    const _obj = {
      course: {
        public: event.target.checked
      }
    };

    this.updateCourse(courseID, _obj);
  }

  updateCourseName(event, course, index) {

    let _obj = {
      course: {
        name: event
      }
    };

    this.selectedCourseField = null;

    this.configCoursesArray[index].name = event;

    this.updateCourse(course.id, _obj);
  }

  updateCourseDesc(event, course, index) {

    let _obj = {
      course: {
        description: event
      }
    };

    this.selectedDescField = null;

    this.configCoursesArray[index].description = event;

    this.updateCourse(course.id, _obj);
  }

  deleteCourse(courseID, courseIndex) {

    if (!courseID) {
      this.notifierService.notify('error', 'Dati non validi!');
      return;
    }

    this._adminService.deleteCalendarCourse(this.selectedLocation.id, courseID).subscribe(res => {

      if (res.success) {
        this.configCoursesArray.splice(courseIndex, 1);
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }

    });
  }

  /**
   *
   * @param courseID
   * @param data
   */
  private updateCourse(courseID, data) {

    this._adminService.updateCalendarCourse(this.selectedLocation.id, courseID, data).subscribe(res => {
      if (res.success) {
        this.notifierService.notify('success', 'Aggiornato con successo!');
        return;
      }
      this.notifierService.notify('error', 'Non può aggiornare!');
    });

  }

  //Tickets
  ticketPublicEvent(event, courseID, ticketID, courseIndex, ticketIndex) {
    const _obj = {
      ticket: {
        public: event.target.checked
      }
    };

    this.updateTicket(courseID, ticketID, courseIndex, ticketIndex, _obj, null, event);
  }

  deleteTicket(courseID, ticketID, courseIndex, ticketIndex) {

    if (!courseID || !ticketID) {
      this.notifierService.notify('error', 'Dati non validi!');
      return;
    }

    this._adminService.deleteCalendarTicket(courseID, ticketID).subscribe(res => {

      if (res.success) {
        this.configCoursesArray[courseIndex].ticket_configurations.splice(ticketIndex, 1);
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }

    });
  }

  editTicket(event, form, courseID, courseIndex) {

    if (form.invalid) {
      return;
    }

    const optionalObj = {
      type: 'edit'
    };

    const _objUpdate = {
      ticket: {
        description: form.value.description,
        name: form.value.name,
        price: form.value.price,
        position: form.value.position
      }
    };

    this.updateTicket(courseID, form.value.ticketID, courseIndex, form.value.ticketIndex, _objUpdate, optionalObj, event);
  }

  addNewTicket(event, form, courseID, courseIndex) {

    if (form.invalid) {
      return;
    }
    form.value["date"] = this.selectedDate;
    this._adminService.addCalendarTicket(courseID, form.value).subscribe(res => {

      if (res.success) {
        form.reset();
        this.getConfigData();
        this.configCoursesArray[courseIndex].ticket_configurations.push(res.data.ticket_configurations);
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }

    });

  }

  /**
   *
   * @param courseID
   * @param ticketID
   * @param courseIndex
   * @param ticketIndex
   * @param data
   * @param optionalObj
   */
  private updateTicket(courseID, ticketID, courseIndex, ticketIndex, data, optionalObj = null, event) {
    data.ticket["date"] = this.selectedDate;
    this._adminService.updateCalendarTicket(courseID, ticketID, data).subscribe(res => {
      if (res.success) {
        this.notifierService.notify('success', 'Aggiornato con successo!');

        if (optionalObj) {

          if (optionalObj.type === 'edit' && res.data.ticket) {
            //add new values
            this.configCoursesArray[courseIndex].ticket_configurations[ticketIndex].name = res.data.ticket.name;
            this.configCoursesArray[courseIndex].ticket_configurations[ticketIndex].description = res.data.ticket.description;
            this.configCoursesArray[courseIndex].ticket_configurations[ticketIndex].price = res.data.ticket.price;
            this.configCoursesArray[courseIndex].ticket_configurations[ticketIndex].position = res.data.ticket.position;

            //reset edit form
            this.editForm._results[courseIndex].reset();
            event.target.parentElement.classList.remove("open-edit-row");
          }

        }

        this.getConfigData();

      }
    });
  }


  //Time Slots
  deleteTimeTable(courseID, timeSlotID, courseIndex, timeSlotIndex) {

    if (!courseID || !timeSlotID) {
      this.notifierService.notify('error', 'Dati non validi!');
      return;
    }

    this._adminService.deleteCalendarTimeSlot(courseID, timeSlotID).subscribe(res => {

      if (res.success) {
        this.configCoursesArray[courseIndex].time_slot_configurations.splice(timeSlotIndex, 1);
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }

    });
  }

  addNewTimeSlot(event, form, courseID, courseIndex) {
    if (form.invalid) {
      this.notifierService.notify('error', 'Dati non validi!');
      return;
    }

    const _obj = {
      time_slot: {
        start_at: form.value.start_at,
        date: this.selectedDate,
        available_tickets: form.value.available_tickets
      }
    };

    this._adminService.addCalendarTimeSlot(courseID, _obj).subscribe(res => {

      if (res.success) {
        form.reset();
        this.getConfigData();
        this.configCoursesArray[courseIndex].time_slot_configurations.push(res.data.time_slot_configurations);
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }

    });

  }

  updateTimeSlot(courseID, timeSlotID, timeSlotIndex, timeSlotValue) {
    if (!timeSlotValue.value || timeSlotValue.value === '' || !isInt(timeSlotValue.value)) {
      timeSlotValue.classList.add('is-invalid');
      this.notifierService.notify('error', 'Dati non validi!');
      return;
    }

    if (!timeSlotID || timeSlotID === '') {
      this.notifierService.notify('error', 'Dati non validi!');
      return;
    }

    const _obj = {
      time_slot: {
        available_tickets: timeSlotValue.value
      }
    };

    this._adminService.updateCalendarTimeSlot(courseID, timeSlotID, _obj).subscribe(res => {
      if (res.success) {
        timeSlotValue.classList.remove('is-invalid');
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }
    });

  }

  disableTimeSlot(ev, courseIndex) {
    const inputEle = jQuery(ev.target),
      rowID = inputEle.attr('data-row');

    if (!rowID || rowID === '') {
      this.notifierService.notify('success', 'Dati ID non validi!');
      return;
    }

    const slotRow = jQuery('.courseBlock_' + courseIndex + ' .slotRowItem[data-row=' + rowID + ']');

    if (slotRow) {
      slotRow.toggleClass('disabled');
      slotRow.find('input').toggleClass('disabled');
      slotRow.find('.save_btn').toggleClass('disabled');

      slotRow.find('input').attr('disabled', function (_, attr) {
        return !attr;
      });
      slotRow.find('.save_btn').attr('disabled', function (_, attr) {
        return !attr;
      });
    }

  }

  private getConfigurationsAPI(locationID: string) {

    this.isLoading = true;
    this._adminService.getConfigByLocation(locationID).subscribe(res => {
      this.isLoading = false;
      if (res.success) {
        this.tickets = res.data.tickets;
      }
    });

  }


  //Datepicker
  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerInlineOptions));
  }

  private dateCounters(datesApi) {

    this.numbers = [];

    let counterDates = [];

    if (datesApi) {

      for (const [key, value] of Object.entries(datesApi)) {
        let parseDate = new Date(key);

        counterDates.push({
          dateObj: {
            year: parseDate.getFullYear(),
            month: parseDate.getMonth() + 1,
            day: parseDate.getDate()
          },
          val: value
        });
      }

    }

    this.numbers = counterDates;
  }

  private disableDays(datesApi) {

    const copy = this.getCopyOfOptions();

    let newDates = [];

    datesApi.forEach(date => {

      let parseDate = new Date(date);

      let obj = {
        year: parseDate.getFullYear(),
        month: parseDate.getMonth() + 1,
        day: parseDate.getDate(),
      };

      newDates.push(obj);

    });

    copy.disableDays = newDates;
    this.myDatePickerInlineOptions = copy;
  }

  private reservedDates(datesApi) {

    const copy = this.getCopyOfOptions();

    let dates = [];

    datesApi.forEach(date => {

      let parseDate = new Date(date);

      let obj = {
        year: parseDate.getFullYear(),
        month: parseDate.getMonth() + 1,
        day: parseDate.getDate(),
      };

      dates.push(obj);

    });

    copy.highlightDates = dates;

    this.myDatePickerInlineOptions = copy;
  }


  private getWeekDayName(dateStr) {
    let date = new Date(dateStr);
    return date.toLocaleDateString('it-IT', {weekday: 'short'});
  }

  ngOnDestroy(): void {
    this.$subscriptionCalendar.unsubscribe();
  }


}
