import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private headers: HttpHeaders;
  notifier: NotifierService;

  private API_URL = environment.API;
  constructor(
    private _http: HttpClient,
    private _notifierService: NotifierService,
  ) {
    this.notifier = this._notifierService;
    this.headers = new HttpHeaders().set('Content-Type', 'application/json');
    // const userData = JSON.parse(localStorage.getItem('userData'));
    this.headers.append('access-token', localStorage.getItem('accessToken'));
    console.log(this.headers);
    this.headers.append('client', localStorage.getItem('client'));
    this.headers.append('token-type', localStorage.getItem('tokenType'));
    this.headers.append('uid', localStorage.getItem('uid'));
  }

  // == Dashboard
  getDashboard(month, year, attraction_id?): Observable<any> {
    const url = (attraction_id) ? `admin/dashboards?month=${month}&year=${year}&attraction_id=${attraction_id}`
                              : `admin/dashboards?month=${month}&year=${year}`

    return this._http.get(this.API_URL + url).pipe(
      map((res: any) => {
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify('error', error.error.errors[0]);
        } else {
          this.notifier.notify('error', 'Si è verificato un errore!');
        }
        return of(result as T);
      })
    );
  }

}
