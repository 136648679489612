import { Injectable } from '@angular/core';
import { Attraction } from '../Models/attraction.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AttrazioniService {

  private headers: HttpHeaders;
  notifier: NotifierService;

  //API_URL = 'https://kalata-api.tandu.it';
  private API_URL = environment.API;
  constructor(
    private _http: HttpClient,
    private _notifierService: NotifierService,
  ) {
    this.notifier = this._notifierService;
    this.headers = new HttpHeaders().set('Content-Type', 'application/json');
    // const userData = JSON.parse(localStorage.getItem('userData'));
    this.headers.append('access-token', localStorage.getItem('accessToken'));
    console.log(this.headers);
    this.headers.append('client', localStorage.getItem('client'));
    this.headers.append('token-type', localStorage.getItem('tokenType'));
    this.headers.append('uid', localStorage.getItem('uid'));
  }

  // == Attractions
  getAttractions(): Observable<any> {
    return this._http.get(this.API_URL + 'admin/attractions').pipe(
      map((res: any) => {
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify('error', error.error.errors[0]);
        } else {
          this.notifier.notify('error', 'Si è verificato un errore!');
        }
        return of(result as T);
      })
    );
  }
  createAttraction(attraction: Attraction) {
    return this._http.post(this.API_URL + 'admin/attractions', attraction).pipe(
      map((res: any) => {
        this.notifier.notify('success', 'Bene inserito con successo!');
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify('error', error.error.errors[0]);
        } else {
          this.notifier.notify('error', 'Si è verificato un errore!');
        }
        return of(result as T);
      })
    );
  }
  editAttraction(attraction: Attraction) {
    return this._http.put(this.API_URL + `admin/attractions/${attraction.id}`, attraction).pipe(
      map((res: any) => {
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify('error', error.error.errors[0]);
        } else {
          this.notifier.notify('error', 'Si è verificato un errore!');
        }
        return of(result as T);
      })
    );
  }
  deleteAttraction(attraction: Attraction) {
    return this._http.delete(this.API_URL + `admin/attractions/${attraction.id}`).pipe(
      map((res: any) => {
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify('error', error.error.errors[0]);
        } else {
          this.notifier.notify('error', 'Si è verificato un errore!');
        }
        return of(result as T);
      })
    );
  }
  getAttractionsForSelectbox(): Observable<any> {
    return this._http.get(this.API_URL + 'admin/attractions/lists').pipe(
      map((res: any) => {
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify('error', error.error.errors[0]);
        } else {
          this.notifier.notify('error', 'Si è verificato un errore!');
        }
        return of(result as T);
      })
    );
  }
  publishAttraction(attraction: Attraction) {
    return this._http.put(this.API_URL + `admin/attractions/${attraction.id}/publish`, attraction).pipe(
      map((res: any) => {
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify('error', error.error.errors[0]);
        } else {
          this.notifier.notify('error', 'Si è verificato un errore!');
        }
        return of(result as T);
      })
    );
  }

  unPublishAttraction(attraction: Attraction) {
    return this._http.put(this.API_URL + `admin/attractions/${attraction.id}/unpublish`, attraction).pipe(
      map((res: any) => {
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify('error', error.error.errors[0]);
        } else {
          this.notifier.notify('error', 'Si è verificato un errore!');
        }
        return of(result as T);
      })
    );
  }

  getCategories(): Observable<any> {
    return this._http.get(this.API_URL + 'admin/attractions/categories').pipe(
      map((res: any) => {
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify('error', error.error.errors[0]);
        } else {
          this.notifier.notify('error', 'Si è verificato un errore!');
        }
        return of(result as T);
      })
    );
  }
}
