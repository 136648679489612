import { Component, OnInit } from '@angular/core';
import { Attraction, TicketConfiguration } from 'src/app/Models/attraction.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AttrazioniService } from 'src/app/services/attrazioni.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-attrazioni',
  templateUrl: './attrazioni.component.html',
  styleUrls: ['./attrazioni.component.scss']
})
export class AttrazioniComponent implements OnInit {

  attractions = new Array<Attraction>();
  countriesList = new Array<string>();
  isLoading = false;
  isAddLoading = false;
  isEdit = false;
  attractionForm: FormGroup;
  editedAttraction: Attraction;

  constructor(private attractionService: AttrazioniService, private formBuilder: FormBuilder,
              private modalService: NgxSmartModalService,  private notifierService: NotifierService,) { }

  ngOnInit() {
    this.isLoading = true;
    this.getAttractions();
    this.getCategories();
    this.attractionForm = this.formBuilder.group({
      name: ['', Validators.required],
      price: ['', Validators.required],
      address: ['', Validators.required],
      category: ['', Validators.required],
      free_ticket: ['']
    });
  }

  getAttractions(){
    this.attractionService.getAttractions().subscribe((respObj) => {
      this.attractions = respObj.data.attractions;
      this.isLoading = false;
    });
  }

  getCategories(){
    this.attractionService.getCategories().subscribe((respObj) => {
      this.countriesList = respObj.data.categories;
      this.attractionForm.patchValue({"category": this.countriesList[0]});
      this.isLoading = false;
    });
  }

  addAttraction() {
    this.modalService.close('attractionAddModal');
    this.isAddLoading = false;
    const attraction = new Attraction();
    attraction.name = this.name.value;
    attraction.ticket_configurations = new Array<TicketConfiguration>();
    attraction.address = this.address.value;
    attraction.category = this.category.value;
    if (!this.isEdit) {
      if(this.free_ticket.value){
        attraction.ticket_configurations = [
          {
            'price':this.price.value,
            'name': 'Biglietto intero'
          },
          {
            'price':0,
            'name': 'Biglietto gratis'
          }];
      }else{
        attraction.ticket_configurations = [
          {
            'price': this.price.value,
            'name': 'Biglietto intero'
          }];
      }
    }
    else {
      this.editedAttraction.name = attraction.name;
      this.editedAttraction.address = attraction.address;
      this.editedAttraction.category = attraction.category;

      for(var i= 0; i< this.editedAttraction.ticket_configurations.length; i++){
        if(this.editedAttraction.ticket_configurations[i]["name"] == 'Biglietto intero'){
          this.editedAttraction.ticket_configurations[i]["price"] = this.price.value;
        }else{
          if(!this.free_ticket.value){
            this.editedAttraction.ticket_configurations[i]["_destroy"] = 1;
          }
        }
      }
      if(this.editedAttraction.ticket_configurations.length == 1 && this.free_ticket.value){
        this.editedAttraction.ticket_configurations.push({
                  'price':0,
                  'name': 'Biglietto gratis'
                })
      }
      this.updateAttraction(this.editedAttraction);
      this.isEdit = false;
      return;
    }
    this.attractionService.createAttraction(attraction).subscribe((resp: any) => this.getAttractions());
    this.attractionForm.reset();
  }


  edit(attraction: Attraction) {
    this.isEdit = true;
    this.name.setValue(attraction.name);
    this.address.setValue(attraction.address);
    this.category.setValue(attraction.category);
    var price;
    if(attraction.ticket_configurations.length == 1){
      price = attraction.ticket_configurations[0]["price"];
      this.free_ticket.setValue(false);
    }else{
      if(attraction.ticket_configurations[0]["name"] == 'Biglietto intero'){
        price = attraction.ticket_configurations[0]["price"];
      }else{
        price = attraction.ticket_configurations[1]["price"];
      }
      this.free_ticket.setValue(true);
    }
   

    if(attraction.ticket_configurations.length == 2){
      this.free_ticket.setValue(true);
    }else{
      this.free_ticket.setValue(false);
    }
    
    this.price.setValue(price);
    this.editedAttraction = attraction;
    this.modalService.open('attractionAddModal');
  }

  publishUnpublish(attraction: Attraction){
    if(attraction.online){
      this.publish(attraction);
    }else{
      this.unpublish(attraction);
    }
  }

  publish(attraction: Attraction) {
    this.attractionService.publishAttraction(attraction).subscribe((resp: any) => {
      this.getAttractions();
    });
  }

  unpublish(attraction: Attraction) {
    this.attractionService.unPublishAttraction(attraction).subscribe((resp: any) => {
      this.getAttractions();
    });
  }

  updateAttraction(attraction: Attraction) {
    this.attractionService.editAttraction(attraction).subscribe((resp: any) => {
      this.getAttractions();
      this.notifierService.notify('success', 'Bene aggiornato con successo!');
    });
  }
  delete(attraction: Attraction) {
    this.attractionService.deleteAttraction(attraction).subscribe((resp: any) => {
      if (resp.success) {
        this.getAttractions();
        this.notifierService.notify('success', 'Bene eliminato con successo!');
       // this.attractions.splice(this.attractions.findIndex(g => g.id == attraction.id), 1);
      }
    });
  }
  openModal() {
    this.attractionForm = this.formBuilder.group({
      name: ['', Validators.required],
      price: ['', Validators.required],
      address: ['', Validators.required],
      category: ['', Validators.required],
      free_ticket: ['']
    });
    this.isEdit = false;
    this.modalService.open('attractionAddModal');
  }

  copyLink(link){
    var dummy = document.createElement("input");
    document.body.appendChild(dummy);

    dummy.setAttribute("id", "dummy_id");
    (<HTMLInputElement>document.getElementById("dummy_id")).value = link;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  get name() { return this.attractionForm.get('name')}
  get price() { return this.attractionForm.get('price')}
  get address() { return this.attractionForm.get('address')}
  get free_ticket() { return this.attractionForm.get('free_ticket')}
  get category() { return this.attractionForm.get('category')}

}
