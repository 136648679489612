import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AngularTokenModule} from 'angular-token';
import {ConfirmationPopoverModule} from 'angular-confirmation-popover';
import {AppComponent} from './app.component';

//Register Local Currency Types in 'IT'
import it from '@angular/common/locales/it';
import {registerLocaleData} from '@angular/common';

registerLocaleData(it);

import {AdminComponent} from './admin/admin.component';
import {LoginComponent} from './login/login.component';
import {CONFIG} from './config/config';
import {MyDatePickerModule} from '../my-date-picker';
import {NotifierModule} from 'angular-notifier';
import {SidebarComponent} from './admin/tpl/sidebar/sidebar.component';
import {HeaderComponent} from './admin/tpl/header/header.component';
import {DashboardComponent} from './admin/dashboard/dashboard.component';
import {ConfigCalendarComponent} from './admin/config-calendar/config-calendar.component';
import {ConfigDateComponent} from './admin/config-date/config-date.component';
import {BookingComponent} from './admin/booking/booking.component';
import {PromoDiscountComponent} from './admin/promo-discount/promo-discount.component';
import {PromoBannerComponent} from './admin/promo-banner/promo-banner.component';
import {AppRoutingModule} from './app-routing.module';
import {ConfigClosuresComponent} from './admin/config-closures/config-closures.component';
import {DropdownDirective} from './dropdown.directive';
import {PageTitleComponent} from './admin/tpl/page-title/page-title.component';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {EditInputComponent} from './admin/tpl/edit-input/edit-input.component';
import {EditTextareaComponent} from './admin/tpl/edit-textarea/edit-textarea.component';
import {ConfigSingleComponent} from './admin/config-single/config-single.component';
import {ErrorInterceptor} from './helpers/interceptor';
import { LocationsComponent } from './admin/locations/locations.component';
import { CalendarioComponent } from './components/calendario/calendario.component';
import { GuideComponent } from './components/guide/guide.component';
import { AttrazioniComponent } from './components/attrazioni/attrazioni.component';
import { ChiusureComponent } from './components/chiusure/chiusure.component';
import { BookingsComponent } from './components/bookings/bookings.component';
import { ReveliaDashboardComponent } from './components/revelia-dashboard/revelia-dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule, MatFormFieldModule, MatNativeDateModule, MatInputModule } from '@angular/material';
import {NgxPaginationModule} from 'ngx-pagination'; 
import { PrettyModelPipe } from './Models/pretty-model.pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminComponent,
    SidebarComponent,
    HeaderComponent,
    DashboardComponent,
    ConfigCalendarComponent,
    ConfigDateComponent,
    ConfigClosuresComponent,
    BookingComponent,
    PromoDiscountComponent,
    PromoBannerComponent,
    DropdownDirective,
    PageTitleComponent,
    EditInputComponent,
    EditTextareaComponent,
    ConfigSingleComponent,
    LocationsComponent,
    CalendarioComponent,
    GuideComponent,
    AttrazioniComponent,
    ChiusureComponent,
    BookingsComponent,
    ReveliaDashboardComponent,
    PrettyModelPipe,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MyDatePickerModule,
    MatDatepickerModule,
    BrowserAnimationsModule,
    FormsModule, 
    MatNativeDateModule, 
    NgxPaginationModule,
    AngularTokenModule.forRoot({
      apiBase: CONFIG.API_URL,
      signInPath: 'auth/login',
      registerAccountPath: 'auth/login',
      signOutPath: 'auth/logout',
      signOutFailedValidate: true
    }),
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 12
        },
        vertical: {
          position: 'top',
          distance: 20,
          gap: 10
        }
      },
      behaviour: {
        autoHide: 2000,
        onClick: 'hide'
      }
    }),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'ok',
      popoverMessage: 'Sei sicuro di voler eliminare?',
      placement: 'top',
      cancelButtonType: 'cancel',
      cancelText: 'Annulla',
      confirmText: 'OK',
    }),
    NgxSmartModalModule.forRoot(),
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
    AngularTokenModule,
    {
      provide: LOCALE_ID,
      useValue: 'it-IT'
    },
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
