import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, NgForm} from '@angular/forms';
import {markAllAsDirty, requiredFileType} from '../../../helpers/helpers';

@Component({
  selector: 'app-edit-textarea',
  templateUrl: './edit-textarea.component.html',
  styleUrls: ['./edit-textarea.component.scss']
})
export class EditTextareaComponent implements OnInit {

  @Input() selectedInput: any;
  @Output() sendInputEvent: EventEmitter<NgForm> = new EventEmitter();
  @Output() cancelEvent: EventEmitter<any> = new EventEmitter();

  file: File;
  inputForm: FormGroup;

  constructor() {
  }

  ngOnInit() {


    this.inputForm = new FormGroup({
      field: new FormControl(this.selectedInput)
    });
  }

  hasError(field: string, error: string) {
    const control = this.inputForm.get(field);
    return control.dirty && control.hasError(error);
  }

  cancel() {
    this.cancelEvent.emit(null);
  }

  emitFiles(event) {
    this.file = event.target.files[0];
  }

  saveInput() {

    if (!this.inputForm.valid) {
      markAllAsDirty(this.inputForm);
      return;
    }

    let data = this.inputForm.value.field;

    this.sendInputEvent.emit(data);
  }

}
