import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {CONFIG} from '../config/config';
import {flatMap, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class UploadsService {

  private headers: HttpHeaders;

  constructor(
    private _http: HttpClient
  ) {
    this.headers = new HttpHeaders().set('Content-Type', 'application/json');
  }

  uploadFile(file: File, resourceId: string, resourceType: string, type?: string): Observable<any> {

    if(!file) return;

    let data = {
      upload: {
        filename: file.name,
        type: file.type,
        owner_id: resourceId,
        owner_type: resourceType,
        banner_type: type
      }
    };

    let uploadId;

    return this
      ._http
      .post(CONFIG.API_URL + 'uploads', data).pipe(
        map((response: any) => {
            return response;
          }
        ),

        flatMap((uploadResponse, index) => {
          uploadId = uploadResponse.upload.id;
          return this
            ._http
            .put(uploadResponse.upload_url, file);
        }),

        flatMap((response, index) => {
          return this
            ._http
            .post(CONFIG.API_URL + 'uploads/' + uploadId + '/done', {}).pipe(
              map((response: any) =>
                response
              )
            );

        })
      );

  }


  deleteFile(file_id: string): Observable<any>{
    return this
      ._http
      .delete(CONFIG.API_URL + 'uploads/' + file_id).pipe(
        map((response: any) => {
            return response;
          }
        )
      );
  }
}
