import {Component, OnInit} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';
import {SharedService} from '../../services/shared.service';

@Component({
  selector: 'app-config-date',
  templateUrl: './config-date.component.html',
  styleUrls: ['./config-date.component.scss']
})
export class ConfigDateComponent implements OnInit {

  selectedLocation: any = null;
  configData: Array<any> = [];
  locations: any[] = null;
  isLoading = false;

  constructor(
    private _adminService: AdminService,
    private _sharedService: SharedService,
    private router: Router,
    private notifierService: NotifierService
  ) {
  }

  ngOnInit() {

    // == Get locations
    this._sharedService.getLocation.subscribe(location => {
      this.locations = location;
      // == Get location from localStorage
      let loc = localStorage.getItem('current_location');
      if(loc){
        this.selectedLocation = loc;
      }else{
        this.selectedLocation = this.locations[0].id;
      }


      // == Get Configuration list
      this.getConfigurationsAPI(this.selectedLocation);
    });
  }

  locationChange(ev) {
    this.selectedLocation = ev.target.value;
    this.getConfigurationsAPI(this.selectedLocation);
    localStorage.setItem('current_location', this.selectedLocation);
  }

  private getConfigurationsAPI(locationID: string) {

    this.isLoading = true;
    this._adminService.getConfigByLocation(locationID).subscribe(res => {
      this.isLoading = false;
      if (res.success) {
        this.configData = res.data.configurations;
      }
    });

  }

  openSingleConfig(configID: string) {

    if (!this.selectedLocation) {
      this.notifierService.notify('warning', 'Si prega di selezionare una posizione!');
      return;
    }

    if (!configID) {
      this.notifierService.notify('warning', 'Si prega di selezionare una configurazione!');
      return;
    }

    this.router.navigate(['/admin/config-date', this.selectedLocation, configID]);
  }

}
