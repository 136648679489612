import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { AdminComponent } from "./admin/admin.component";
import { AngularTokenService } from "angular-token";
import { DashboardComponent } from "./admin/dashboard/dashboard.component";
import { ConfigCalendarComponent } from "./admin/config-calendar/config-calendar.component";
import { ConfigDateComponent } from "./admin/config-date/config-date.component";
import { ConfigClosuresComponent } from "./admin/config-closures/config-closures.component";
import { BookingComponent } from "./admin/booking/booking.component";
import { PromoDiscountComponent } from "./admin/promo-discount/promo-discount.component";
import { PromoBannerComponent } from "./admin/promo-banner/promo-banner.component";
import { ConfigSingleComponent } from "./admin/config-single/config-single.component";
import { LocationsComponent } from "./admin/locations/locations.component";
import { CalendarioComponent } from "./components/calendario/calendario.component";
import { GuideComponent } from "./components/guide/guide.component";
import { AttrazioniComponent } from "./components/attrazioni/attrazioni.component";
import { ChiusureComponent } from "./components/chiusure/chiusure.component";
import { BookingsComponent } from "./components/bookings/bookings.component";
import { ReveliaDashboardComponent } from "./components/revelia-dashboard/revelia-dashboard.component";

const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "admin",
    component: AdminComponent,
    canActivate: [AngularTokenService],
    children: [
      {
        path: "dashboard",
        component: DashboardComponent,
        data: { title: "Dashboard" },
      },
      {
        path: "revelia-dashboard",
        component: ReveliaDashboardComponent,
        data: { title: "Dashboard" },
      },
      {
        path: "guide",
        component: GuideComponent,
        data: { title: "Guide" },
      },
      {
        path: "chiusure",
        component: ChiusureComponent,
        data: { title: "Chiusure" },
      },
      {
        path: "calendario",
        component: CalendarioComponent,
        data: { title: "Calendario" },
      },
      {
        path: "attrazioni",
        component: AttrazioniComponent,
        data: { title: "Beni" },
      },
      {
        path: "revelia-bookings",
        component: BookingsComponent,
        data: { title: "Prenotazioni e incassi" },
      },
      {
        path: "config-calendar",
        component: ConfigCalendarComponent,
        data: { title: "Calendario" },
      },
      {
        path: "config-date",
        component: ConfigDateComponent,
        data: { title: "Apri date" },
      },
      {
        path: "config-date/:locationID/:configID",
        component: ConfigSingleComponent,
        data: { title: "Apri date" },
      },
      {
        path: "config-closures",
        component: ConfigClosuresComponent,
        data: { title: "Chiusure" },
      },
      {
        path: "bookings",
        component: BookingComponent,
        data: { title: "Prenotazioni e incassi" },
      },
      {
        path: "promo-discount",
        component: PromoDiscountComponent,
        data: { title: "Sconti biglietti", typology: "promozione" },
      },
      {
        path: "discount-code",
        component: PromoDiscountComponent,
        data: { title: "Codici sconto", typology: "codice_sconto" },
      },
      {
        path: "promo-banner",
        component: PromoBannerComponent,
        data: { title: "Banner mail" },
      },
      {
        path: "locations",
        component: LocationsComponent,
        data: { title: "Modifica location" },
      },
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
    ],
  },
  { path: "**", redirectTo: "login" },
];

@NgModule({
  declarations: [],
  imports: [	RouterModule.forRoot(appRoutes, {onSameUrlNavigation: 'reload'}), CommonModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
