import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {AngularTokenService} from 'angular-token';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  userData: any = {};

  isLoading: boolean = false;

  constructor(private authService: AuthService,
              private tokenService: AngularTokenService,
              private router: Router) {
  }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
  }

  logOut(event) {
    event.preventDefault();

    this.isLoading = true;

    this.tokenService.signOut().subscribe(
      res => {
        this.isLoading = false;
        localStorage.removeItem("userData");
        this.router.navigateByUrl('/');
      },
      error => {
        this.isLoading = false;
        this.router.navigateByUrl('/');
      }
    );

  }

}
