import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { Observable, of } from 'rxjs';
import { CONFIG } from '../config/config';
import { map, catchError } from 'rxjs/operators';
import { Guide } from '../Models/guide.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GuideService {

  private headers: HttpHeaders;
  notifier: NotifierService;

  private API_URL = environment.API;
  constructor(
    private _http: HttpClient,
    private _notifierService: NotifierService,
  ) {
    this.notifier = this._notifierService;
    this.headers = new HttpHeaders().set('Content-Type', 'application/json');
    // const userData = JSON.parse(localStorage.getItem('userData'));
    this.headers.append('access-token', localStorage.getItem('accessToken'));
    console.log(this.headers);
    this.headers.append('client', localStorage.getItem('client'));
    this.headers.append('token-type', localStorage.getItem('tokenType'));
    this.headers.append('uid', localStorage.getItem('uid'));
  }

  // == Guides
  getGuides(): Observable<any> {
    return this._http.get(this.API_URL + 'admin/guides').pipe(
      map((res: any) => {
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify('error', error.error.errors[0]);
        } else {
          this.notifier.notify('error', 'Si è verificato un errore!');
        }
        return of(result as T);
      })
    );
  }
  getGuidesForBox(): Observable<any> {
    return this._http.get(this.API_URL + 'admin/guides/lists').pipe(
      map((res: any) => {
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify('error', error.error.errors[0]);
        } else {
          this.notifier.notify('error', 'Si è verificato un errore!');
        }
        return of(result as T);
      })
    );
  }
  createGuide(guide: Guide) {
    return this._http.post(this.API_URL + 'admin/guides', guide).pipe(
      map((res: any) => {
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify('error', error.error.errors[0]);
        } else {
          this.notifier.notify('error', 'Si è verificato un errore!');
        }
        return of(result as T);
      })
    );
  }
  editGuide(guide: Guide) {
    return this._http.put(this.API_URL + `admin/guides/${guide.id}`, guide).pipe(
      map((res: any) => {
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify('error', error.error.errors[0]);
        } else {
          this.notifier.notify('error', 'Si è verificato un errore!');
        }
        return of(result as T);
      })
    );
  }
  deleteGuide(guide: Guide) {
    return this._http.delete(this.API_URL + `admin/guides/${guide.id}`).pipe(
      map((res: any) => {
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify('error', error.error.errors[0]);
        } else {
          this.notifier.notify('error', 'Si è verificato un errore!');
        }
        return of(result as T);
      })
    );
  }

  exportGuide(start_date, end_date, guide_id){
    return this._http.get(this.API_URL + '/admin/guides/download?start_date='+ start_date + '&end_date='+ end_date + '&guide_id=' + guide_id, { responseType: 'blob' }).subscribe(
      data => {
         var file = new Blob([data], {type: 'application/pdf'});
         var fileURL = URL.createObjectURL(file);
         window.open(fileURL);
      }
  );
  }
}
