import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyModel'
})
export class PrettyModelPipe implements PipeTransform {

    transformCollection(value: any[], maxCount: number = 2) : string {
        if(value == null || value.length == 0) return '';
        if(value.length == 1 && maxCount >= 1) return this.transform(value[0]);
        if(value.length > 1 && value.length <= maxCount){
          let output = '';
          for(let i=0; i<value.length; i++){
            if(output.length > 0) output += ', ';
            output += this.transform(value[i]);
          }
          return output;
        }
        if(value.length > maxCount) return value.length + (value.length == 1 ? ' elemento' : ' elementi');
      }
    
      translate(value: string, translations: Object, fallback: string){
        return translations[value] || fallback || value;
      }
    
      transform(value: any, args?: string|number, fallback?: string): string {
        if(value == null) return '';
        if(value instanceof Array) {
          if(typeof args === 'string'){
            console.warn('PrettyPipe called with string argument on array: ')
            console.warn(value)
          }
          return this.transformCollection(value, args as number);
        }
    
        // Try to transform with known pipe
        let pipe : PipeTransform = null;
        // if (value instanceof GolfClub) pipe = new PrettyGolfPipe();
        if (pipe != null) return pipe.transform(value);
    
        // Try to transform with given string translation
        if (typeof args === 'string' && typeof value === 'string' && this._translations[args]){
          return this.translate(value, this._translations[args], fallback)
        }
        if (typeof value === 'boolean'){
          return value ? 'Si' : 'No';
          //return this.translate(value+'', this._translations[args], fallback)
        }
    
        console.warn('Unable to apply PrettyPipe to: ');
        console.warn(value);
        return fallback || value.toString();
      }
    
      private _translations = {
        prettyCategory:{
          kalata: "Kalatà",
          abbonamento_musei: "Abbonamento musei",
        }
      }
}