import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { AdminService } from "../../services/admin.service";
import { from, Subscription } from "rxjs";
import * as moment from "moment";
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import { SharedService } from "../../services/shared.service";
import { IMyDateModel, IMyDpOptions } from "../../../my-date-picker/interfaces";
import { getFirstDayPreviousMonth } from '../../helpers/helpers';

export enum PAYMENT_STATE {
  PAYED_STATUS = "payed",
  TO_PAY_STATUS = "to_pay",
  FREE_STATUS = "free",
}

@Component({
  selector: "app-booking",
  templateUrl: "./booking.component.html",
  styleUrls: ["./booking.component.scss"],
})
export class BookingComponent implements OnInit {
  date: Date = new Date();
  locations: Array<any> = [];
  calendarForm: FormGroup;
  currentLocation: string;
  selectedLocation: { id: string; name: string } = { id: "", name: "" };
  ordersData: any;
  isLoading: boolean = false;
  paymentState: Array<{ value: string; name: string }> = [
    { value: PAYMENT_STATE.PAYED_STATUS, name: "Pagato" },
    { value: PAYMENT_STATE.TO_PAY_STATUS, name: "Da pagare" },
    { value: PAYMENT_STATE.FREE_STATUS, name: "Free" },
  ];
  paymentStateFilter: PAYMENT_STATE = null;

  private subscriptionTwo$: Subscription;

  formMyDatePickerOptions: IMyDpOptions = {
    dateFormat: "dd/mm/yyyy",
    monthLabels: {
      1: "Gennaio",
      2: "Febbraio",
      3: "Marzo",
      4: "Aprile",
      5: "Maggio",
      6: "Giugno",
      7: "Luglio",
      8: "Agosto",
      9: "Settembre",
      10: "Ottobre",
      11: "Novembre",
      12: "Dicembre",
    },
    dayLabels: {
      su: "Dom",
      mo: "Lun ",
      tu: "Mar",
      we: "Mer",
      th: "Gio",
      fr: "Ven",
      sa: "Sab",
    },
    sunHighlight: false,
    selectorHeight: "auto",
    selectorWidth: "410px",
    allowSelectionOnlyInCurrentMonth: false,
    disableHeaderButtons: true,
    yearSelector: false,
    monthSelector: false,
    showTodayBtn: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: false,
    editableDateField: false,
    showSelectorArrow: false,
    inline: false,
  };

  toMyDatePickerOptions: IMyDpOptions = {
    dateFormat: "dd/mm/yyyy",
    monthLabels: {
      1: "Gennaio",
      2: "Febbraio",
      3: "Marzo",
      4: "Aprile",
      5: "Maggio",
      6: "Giugno",
      7: "Luglio",
      8: "Agosto",
      9: "Settembre",
      10: "Ottobre",
      11: "Novembre",
      12: "Dicembre",
    },
    dayLabels: {
      su: "Dom",
      mo: "Lun ",
      tu: "Mar",
      we: "Mer",
      th: "Gio",
      fr: "Ven",
      sa: "Sab",
    },
    // disableUntil: {
    //   year: 2022,
    //   month: 4,
    //   day: 28,
    // },
    // disableDays: [{ year: 0, month: 0, day: 0 }],
    alignSelectorRight: true,
    sunHighlight: false,
    selectorHeight: "auto",
    selectorWidth: "410px",
    allowSelectionOnlyInCurrentMonth: false,
    disableHeaderButtons: true,
    yearSelector: false,
    monthSelector: false,
    showTodayBtn: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: false,
    editableDateField: false,
    showSelectorArrow: false,
    inline: false,
  };

  localeIt: any = "it";

  filterData: any = {
    name: "",
  };

  fromDate: any;
  toDate: any;
  page: number = 1;
  per_page: number = 20;
  total: number;

  ordersFiltered: any;

  constructor(
    private _sharedService: SharedService,
    private _adminService: AdminService,
    private cdRef: ChangeDetectorRef,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.calendarForm = this.fb.group({
      attraction: ["", Validators.required],
      from_date: [""],
      to_date: [""],
      guide_id: [""],
    });

    //== Get orders by location
    this._sharedService.getLocation.subscribe((location) => {
      this.locations = location;

      // == Get location from localStorage
      let loc = localStorage.getItem("current_location");
      if (loc) {
        this.currentLocation = loc;
      } else {
        this.currentLocation = this.locations[0].id;
      }

      let current = this.locations.find(x => x.id === this.currentLocation)

      this.selectedLocation = {
        id: current.id,
        name: current.name
      };
      this.setDate();
      this.getOrdersApi(this.currentLocation, this.fromDate, this.toDate);
    });
  }


  ngAfterViewInit() {
    this.setDate();
    this.cdRef.detectChanges();
  }

  setDate() {
    let date = new Date();
    let prevDate = getFirstDayPreviousMonth();
    this.calendarForm.patchValue({
      from_date: {
        date: {
          year: prevDate.getFullYear(),
          month: prevDate.getMonth() + 1,
          day: prevDate.getDate(),
        },
      },
    });
    this.calendarForm.patchValue({
      to_date: {
        date: {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
        },
      },
    });
    this.fromDate = this.calendarForm.value.from_date.date;
    this.toDate = this.calendarForm.value.to_date.date;
    this.disableUtil();
    this.cdRef.detectChanges();
  }

  toggleElement(event) {
    let targetParent = event.currentTarget;

    targetParent.parentElement.classList.toggle("open");
    targetParent.parentElement.children[1].classList.toggle("show");
  }

  changeLocation(ev) {
    this.selectedLocation = {
      id: ev.target.value,
      name: ev.target.options[ev.target.selectedIndex].getAttribute("name"),
    };
    // localStorage.setItem("current_location", ev.target.value);
    this.getOrdersApi(
      ev.target.value,
      this.fromDate,
      this.toDate,
      this.paymentStateFilter
    );
  }

  getCopyOfToMyDatePickerOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.toMyDatePickerOptions));
  }

  disableUtil() {
    let copy: IMyDpOptions = this.getCopyOfToMyDatePickerOptions();
    copy.disableUntil = this.fromDate;
    this.toMyDatePickerOptions = copy;
    this.cdRef.detectChanges();
  }

  onDateChangedFrom(event: IMyDateModel) {
    this.fromDate = event.date;
    this.getOrdersApi(
      this.selectedLocation.id,
      this.fromDate,
      this.toDate,
      this.paymentStateFilter
    );
  }

  onToDateChangedTo(event: IMyDateModel) {
    this.toDate = event.date;
    this.getOrdersApi(
      this.selectedLocation.id,
      this.fromDate,
      this.toDate,
      this.paymentStateFilter
    );
  }

  onSearchName() {
    this.page = 1;
    this.getOrdersApi(
      this.selectedLocation.id,
      this.fromDate,
      this.toDate,
      this.paymentStateFilter,
      this.filterData.name
    );
  }

  onPaymentStateChanged(state: PAYMENT_STATE) {
    this.page = 1;
    this.paymentStateFilter = state;
    this.getOrdersApi(
      this.selectedLocation.id,
      this.fromDate,
      this.toDate,
      this.paymentStateFilter,
      this.filterData.name
    );
  }

  removeFilters() {
    // this.fromDate = null;
    // this.toDate = null;
    this.paymentStateFilter = null;
    this.filterData.name = null;
    this.getOrdersApi(this.selectedLocation.id, this.fromDate, this.toDate);
  }

  pageChanged(page: number) {
    this.page = page;
    this.getOrdersApi(
      this.selectedLocation.id,
      this.fromDate,
      this.toDate,
      this.paymentStateFilter,
      this.filterData.name
    );
  }

  /**
   *
   * @param locationID
   */
  private getOrdersApi(
    locationID: string,
    fromDate?: string,
    toDate?: string,
    paymentState?: PAYMENT_STATE,
    name?: string
  ) {
    this.isLoading = true;
    let params: Array<any> = new Array<any>();
    if (fromDate) {
      params.push(
        `date_from=${fromDate["year"]}-${fromDate["month"] > 9 ? fromDate["month"] : "0" + fromDate["month"]
        }-${fromDate["day"] > 9 ? fromDate["day"] : "0" + fromDate["day"]}`
      );
    }
    if (toDate) {
      params.push(
        `date_to=${toDate["year"]}-${toDate["month"] > 9 ? toDate["month"] : "0" + toDate["month"]
        }-${toDate["day"] > 9 ? toDate["day"] : "0" + toDate["day"]}`
      );
    }
    if (paymentState) params.push(`status=${paymentState}`);
    if (this.page) params.push(`page=${this.page}`);
    if (this.per_page) params.push(`per_page=${this.per_page}`);

    if (name) {
      params.push(`name=${name}`);
    }
    this.subscriptionTwo$ = this._adminService
      .getOrders(locationID, params)
      .subscribe((res) => {
        this.ordersData = res.success ? res.data : null;
        this.ordersFiltered = this.ordersData;
        this.total = this.ordersData.total;
        this.page = this.ordersData.pagination.current_page;
        this.per_page = this.ordersData.pagination.per_page;
        this.isLoading = false;
      });
  }

  downloadCsv() {
    let params: Array<any> = new Array<any>();
    if (this.fromDate) {
      params.push(
        `date_from=${this.fromDate["year"]}-${this.fromDate["month"] > 9
          ? this.fromDate["month"]
          : "0" + this.fromDate["month"]
        }-${this.fromDate["day"] > 9
          ? this.fromDate["day"]
          : "0" + this.fromDate["day"]
        }`
      );
    }
    if (this.toDate) {
      params.push(
        `date_to=${this.toDate["year"]}-${this.toDate["month"] > 9
          ? this.toDate["month"]
          : "0" + this.toDate["month"]
        }-${this.toDate["day"] > 9 ? this.toDate["day"] : "0" + this.toDate["day"]
        }`
      );
    }
    this._adminService
      .downloadCsv(this.selectedLocation.id, params)
      .subscribe((res: any) => {
        var a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(res);
        a.download = "Prenotazioni.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }
}
