import {Component, Renderer2} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  isMenuOpen: boolean = false;
  icon: string = 'bars';

  constructor(private renderer: Renderer2) {
  }

  toggleNav($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.isMenuOpen = !this.isMenuOpen;
    this.renderer[this.isMenuOpen ? 'addClass' : 'removeClass'](document.body, 'menu-open');
    this.icon = this.isMenuOpen ? 'times' : 'bars';
  }

}
