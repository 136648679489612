import {Injectable} from '@angular/core';
import {AngularTokenService} from 'angular-token';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private tokenService: AngularTokenService,
    private router: Router,
  ) {
  }

  get isAuth() {
    return this.tokenService.userSignedIn();
  }

  redirectAuth() {
    if (this.isAuth) {
      let path = window.location.pathname;
      if(path){
        this.router.navigateByUrl(path);
      }else{
        this.router.navigateByUrl('/admin');
      }
    } else {
      this.router.navigateByUrl('/');
    }
    return;
  }

  logoutOnError(){
    this.tokenService.signOut().subscribe(
      res => {
        this.router.navigateByUrl('/');
      },
      error => {
        this.router.navigateByUrl('/');
      }
    );
  }
}
