import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { AttrazioniService } from 'src/app/services/attrazioni.service';
import { Attraction } from 'src/app/Models/attraction.model';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-revelia-dashboard',
  templateUrl: './revelia-dashboard.component.html',
  styleUrls: ['./revelia-dashboard.component.scss']
})
export class ReveliaDashboardComponent implements OnInit {

  date: Date = new Date();

  months: Array<any> = [];

  years: Array<number> = [];

  isLoading: boolean = false;

  dashboardData: any;

  selectedDate: { year: number, month: number } = {year: 0, month: 0};

  locations = new Array<Attraction>();
  selectedLocation: any;
  currentLocation: string;
  constructor(
    private _adminService: AdminService,
    private attractionService: AttrazioniService,
    private dashboardService: DashboardService
  ) {
  }

  ngOnInit() {

    //fill Select box
    this.getLocations();
    this.getYears();
    this.getMonths();
    //dashboard data
    this.selectedDate = {
      month: this.date.getMonth() + 1,
      year: this.date.getFullYear(),
    };
  }


  yearChange(ev) {
    this.selectedDate.year = ev.target.value;
    this.getDashboardByDate(this.selectedDate, this.selectedLocation.id);
  }

  monthChange(ev) {
    this.selectedDate.month = ev.target.value;
    this.getDashboardByDate(this.selectedDate, this.selectedLocation.id);
  }

  private getDashboardByDate(date: any, attraction_id?) {

    this.isLoading = true;

    this.dashboardService.getDashboard(date.month, date.year, attraction_id? attraction_id: null).subscribe(res => {
      this.isLoading = false;
      this.dashboardData = res.data.dashboard;
    });
  }

  private getYears() {
    let yy = this.date.getFullYear();
    for (let i = (yy - 10); i <= yy; i++) {
      this.years.push(i);
    }
  }

  private getLocations() {
    this.attractionService.getAttractionsForSelectbox().subscribe((res: any) => {
      this.locations = res.data.attractions;

      //const loc = localStorage.getItem('current_location_revelia');
        // if (loc) {
        //   this.currentLocation = loc;
        // } else {
        //   this.currentLocation = this.locations[0].id;
        // }

        this.selectedLocation = {
          id: this.currentLocation
        };
        this.getDashboardByDate(this.selectedDate, this.currentLocation);

    })
  }
  locationChange(ev) {
    if(ev.target.value == "null"){
      this.selectedLocation = {};
    }else{
      this.selectedLocation = {
        id: ev.target.value,
        name: ev.target.options[ev.target.selectedIndex].getAttribute('name')
      };
    }
    this.getDashboardByDate(this.selectedDate, this.selectedLocation.id);
  }
  private getMonths() {

    this.months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
  }

  monthNameToNum(monthname) {
    let month = this.months.indexOf(monthname);
    return month != -1 ? month + 1 : undefined;
  }
}
