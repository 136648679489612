import {Component, OnInit, ViewChildren} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AdminService} from '../../services/admin.service';
import {NotifierService} from 'angular-notifier';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {IMyDpOptions} from '../../../my-date-picker/interfaces';
import * as moment from 'moment';
import {SharedService} from '../../services/shared.service';
import {isInt} from '../../helpers/helpers';
import * as jQuery from 'jquery';

@Component({
  selector: 'app-config-single',
  templateUrl: './config-single.component.html',
  styleUrls: ['./config-single.component.scss']
})
export class ConfigSingleComponent implements OnInit {

  private date: Date = new Date();

  todayDate: any = {year: 0, month: 0, day: 0};

  locationID$: string = null;

  configID$: string = null;

  configData: any = {};

  tickets;

  configCoursesArray: Array<any> = null;

  isLoading: boolean = false;

  selectedCourseField: any = null;

  @ViewChildren('editForm') editForm;

  //new form
  newDateForm: FormGroup;

  isFormSubmitted: boolean = false;

  isFormLoading: boolean = false;

  formHasError: boolean = false;

  myDatePickerInlineOptions: IMyDpOptions = {
    dateFormat: 'mmm, yyyy dd',
    minYear: this.date.getFullYear() - 1,
    maxYear: this.date.getDate(),
    monthLabels: {
      1: 'Gennaio',
      2: 'Febbraio',
      3: 'Marzo',
      4: 'Aprile',
      5: 'Maggio',
      6: 'Giugno',
      7: 'Luglio',
      8: 'Agosto',
      9: 'Settembre',
      10: 'Ottobre',
      11: 'Novembre',
      12: 'Dicembre'
    },
    disableUntil: {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() - 1},
    disableDays: [{year: 0, month: 0, day: 0}],
    // disableSince: {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() + 1},
    selectorHeight: 'auto',
    selectorWidth: '410px',
    allowSelectionOnlyInCurrentMonth: false,
    disableHeaderButtons: true,
    yearSelector: false,
    monthSelector: false,
    showTodayBtn: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: false,
    editableDateField: false,
    showSelectorArrow: false,
    inline: false,
  };

  localeIt: any = 'it';

  locationInfo: any = null;

  wDays: Array<any> = [
    {
      name: 'Lunedi',
      value: 1
    },
    {
      name: 'Martedì',
      value: 2
    },
    {
      name: 'Mercoledì',
      value: 3
    },
    {
      name: 'Giovedì',
      value: 4
    },
    {
      name: 'Venerdì',
      value: 5
    },
    {
      name: 'Sabato',
      value: 6
    },
    {
      name: 'Domenica',
      value: 0
    },
  ];

  selectedWDays = [];

  wDaysError: boolean = true;

  ticketsError: boolean = true;

  ticketArrayData: Array<any> = [];

  selectedTickets = [];

  name = 'Biglietto aperto';


  constructor(
    private _adminService: AdminService,
    private _sharedService: SharedService,
    private route: ActivatedRoute,
    public ngxSmartModalService: NgxSmartModalService,
    private formBuilder: FormBuilder,
    private notifierService: NotifierService) {
    this.todayDate = {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate()};

  }

  ngOnInit() {

    //get router params
    this.locationID$ = this.route.snapshot.params['locationID'];
    this.configID$ = this.route.snapshot.params['configID'];


    // == Get Configuration list
    this.getSingleConfig();


    this.getConfigurationsAPI(this.locationID$);


    // == New Config Form Builder
    this.newDateForm = this.formBuilder.group({
      courseInput: ['', Validators.required],
      orderDateFrom: [this.todayDate, Validators.required],
      orderDateTo: [this.todayDate, Validators.required],
      wDays: this.addWDaysControls(),
      tickets: this.formBuilder.array([]),
    });
  }

  getSingleConfig(){
    this.isLoading = true;
    this._adminService.getSingleConfig(this.locationID$, this.configID$).subscribe(res => {
      this.isLoading = false;
      if (res.success) {
        this.configData = res.data.configuration;
        this.configCoursesArray = res.data.configuration.course_configurations;
      }
    });
  }



  openNewRowEv(ev) {
    ev.target.parentElement.parentElement.parentElement.parentElement.classList.toggle('open-new-row');
  }

  openEditRowEv(ev, courseIndex, ticket, ticketIndex) {

    let parent = ev.target.parentElement.parentElement.parentElement.parentElement;

    if (ev.target.classList.contains('fas')) {
      parent = ev.target.parentElement.parentElement.parentElement.parentElement.parentElement;
    }

    if (!parent.classList.contains('open-edit-row')) {
      parent.classList.toggle('open-edit-row');
    }
    let editFORM = null;

    for(let form of this.editForm._results){
      if(form.controls.index.value === courseIndex){
        editFORM = form;
      }
    }

    //const editFORM = this.editForm._results[courseIndex];

    //fill form rows
    editFORM.controls.name.setValue(ticket.name);
    editFORM.controls.position.setValue(ticket.position);
    editFORM.controls.description.setValue(ticket.description);
    editFORM.controls.price.setValue(ticket.price);
    editFORM.controls.ticketID.setValue(ticket.id);
    editFORM.controls.ticketIndex.setValue(ticketIndex);

  }

  closeEditRow(ev, courseIndex) {
    let parent = ev.target.parentElement.parentElement.parentElement.parentElement.parentElement;

    // if (ev.target.classList.contains('fas')) {
    //   parent = ev.target.parentElement.parentElement.parentElement.parentElement.parentElement;
    // }

    parent.classList.toggle('open-edit-row');
    //parent.getElementsByClassName("open-edit-row").toggle('open-edit-row');

    // parent.classList.remove('open-edit-row');
    // parent.classList.add('close-edit-row');

    //reset edit form
    for(let form of this.editForm._results){
      if(form.controls.index.value === courseIndex){
        this.resetForm(form);
      }
    }

   // this.editForm._results[courseIndex].reset();
  }


  resetForm(form){
    form.controls.name.setValue("");
    form.controls.position.setValue("");
    form.controls.description.setValue("");
    form.controls.price.setValue("");
    
    form.controls.ticketID ? form.controls.ticketID.setValue("") : "";
    form.controls.ticketIndex ? form.controls.ticketIndex.setValue("") : "";
  }

  coursePublicEvent(event, courseID) {
    const _obj = {
      course_configuration: {
        public: event.target.checked
      }
    };

    this.updateCourse(courseID, _obj);
  }

  editConfigNameField(field, type) {
    this.selectedCourseField = {
      field,
      type
    };
  }

  updateConfigName(event) {

    let _obj = {
      configuration: {
        name: event
      }
    };

    this.selectedCourseField = null;

    this.configData.name = event;

    this.updateConfig(_obj);
  }

  //New Date

  get newDateControls() {
    return this.newDateForm.controls;
  }

  get wDaysArray() {
    return <FormArray>this.newDateForm.get('wDays');
  }

  get ticketsArray() {
    return <FormArray>this.newDateForm.get('tickets');
  }

  getSelectedWDaysValue() {
    this.selectedWDays = [];

    this.wDaysArray.controls.forEach((control, i) => {
      if (control.value) {
        this.selectedWDays.push(this.wDays[i]);
      }
    });

    this.wDaysError = this.selectedWDays.length <= 0;
  }

  getSelectedTicketValue() {

    this.selectedTickets = [];

    this.ticketsArray.controls.forEach((control, i) => {
      if (control.value) {
        this.selectedTickets.push(this.ticketArrayData[i]);
      }
    });

    this.ticketsError = this.selectedTickets.length <= 0;
  }

  addWDaysControls() {
    const days = this.wDays.map(() => {
      return this.formBuilder.control(false);
    });

    return this.formBuilder.array(days);
  }

  addTicketControls() {
    this.ticketArrayData.map(() => {
      this.ticketsArray.push(this.formBuilder.control(false));
    });
  }

  resetTicketControls() {

    while (this.ticketsArray.length !== 0) {
      this.ticketsArray.removeAt(0);
    }

    this.ticketsError = true;
  }

  openNewDateModal() {

    //get location info
    this._sharedService.getLocation.subscribe(location => {
      this.locationInfo = location.find(x => x.id === this.locationID$);
    });

    //open modal
    this.ngxSmartModalService.setModalData({}, 'openDateModal');
    this.ngxSmartModalService.open('openDateModal');
  }

  changeCourse(e) {

    //reset tickets forms
    this.ticketArrayData = [];

    this.resetTicketControls();

    const courseId = e.target.value;

    this.newDateControls.courseInput.setValue(e.target.value, {
      onlySelf: true
    });

    //fill ticket for this course
    const courseItem = this.configCoursesArray.find(course => course.id === courseId);

    if (courseItem) {

      if (courseItem.ticket_configurations.length > 0) {
        this.ticketArrayData = courseItem.ticket_configurations;
        this.addTicketControls();
      }
    }
  }

  submitNewForm() {
    this.isFormSubmitted = true;

    if (this.newDateForm.invalid || this.wDaysError || this.ticketsError) {
      return false;
    }

    this.isFormLoading = true;

    //create post data _obj
    const formValue = this.newDateForm.value;

    //format datepicker
    const from_date = moment.unix(formValue.orderDateFrom.epoc).format('YYYY-MM-DD'),
      to_date = moment.unix(formValue.orderDateTo.epoc).format('YYYY-MM-DD');

    let _obj = {
      'from': from_date,
      'to': to_date,
      'course_configurations_id': formValue.courseInput,
      'ticket_configuration_ids': this.selectedTickets.map(x => x.id),
      'wdays': this.selectedWDays.map(x => x.value)
    };

    //open date API
    this._adminService.openNewDate(this.locationID$, this.configID$, _obj).subscribe(res => {
      if (res.success) {
        this.ngxSmartModalService.close('openDateModal');
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }
      this.isFormLoading = false;
    });

  }


  // Ticket
  deleteTicket(courseID, ticketID, courseIndex, ticketIndex) {
    if (!courseID || !ticketID) {
      this.notifierService.notify('error', 'Dati non validi!');
      return;
    }

    this._adminService.deleteTicketConfiguration(courseID, ticketID).subscribe(res => {

      if (res.success) {
        this.getSingleConfig();
        // if(this.configData){
        //   this.configData[courseIndex].ticket_configurations.splice(ticketIndex, 1);
        // }
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }

    });
  }

  ticketPublicEvent(event, courseID, ticketID, courseIndex, ticketIndex) {
    const _obj = {
      'ticket_configuration': {
        public: event.target.checked
      }
    };

    this.updateTicket(courseID, ticketID, courseIndex, ticketIndex, _obj, null, event);
  }

  addNewTicket(event, form, courseID, courseIndex) {

    if (form.invalid) {
      return;
    }

    this._adminService.addNewTicketConfiguration(courseID, form.value).subscribe(res => {

      if (res.success) {
        // form.reset();
        this.resetForm(form);
        this.getSingleConfig();
        if(this.configData){
          this.configData.course_configurations[courseIndex].ticket_configurations.push(res.data.ticket_configurations);
        }
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }

    });

  }

  editTicket(event, form, courseID, courseIndex) {

    if (form.invalid) {
      return;
    }

    const optionalObj = {
      type: 'edit'
    };

    const _objUpdate = {
      description: form.value.description,
      name: form.value.name,
      price: form.value.price,
      position: form.value.position
    };

    this.updateTicket(courseID, form.value.ticketID, courseIndex, form.value.ticketIndex, _objUpdate, optionalObj, event);
  }

  editCourseField(field, type) {
    this.selectedCourseField = {
      field,
      type
    };
  }

  updateCourseName(event, course, index) {

    let _obj = {
      course_configuration: {
        name: event
      }
    };

    this.selectedCourseField = null;

    this.configData.course_configurations[index].name = event;

    this.updateCourse(course.id, _obj);
  }

  /**
   *
   * @param courseID
   * @param ticketID
   * @param courseIndex
   * @param ticketIndex
   * @param data
   * @param optionalObj
   */
  private updateTicket(courseID, ticketID, courseIndex, ticketIndex, data, optionalObj = null, event) {

    this._adminService.updateTicketConfiguration(courseID, ticketID, data).subscribe(res => {
      if (res.success) {
        event.target.parentElement.classList.remove("open-edit-row");
        this.notifierService.notify('success', 'Aggiornato con successo!');

        if (optionalObj) {

          if (optionalObj.type === 'edit') {
            //add new values
            this.configData.course_configurations[courseIndex].ticket_configurations[ticketIndex].name = res.data.ticket_configurations.name;
            this.configData.course_configurations[courseIndex].ticket_configurations[ticketIndex].description = res.data.ticket_configurations.description;
            this.configData.course_configurations[courseIndex].ticket_configurations[ticketIndex].price = res.data.ticket_configurations.price;
            this.configData.course_configurations[courseIndex].ticket_configurations[ticketIndex].position = res.data.ticket_configurations.position;

            //reset edit form
            //this.editForm._results[courseIndex].reset();
            this.resetForm(this.editForm._results[courseIndex]);
          }

        }

        this.getSingleConfig();

      }
    });
  }

  /**
   *
   * @param courseID
   * @param data
   */
  private updateCourse(courseID, data) {

    this._adminService.updateCourseConfig(courseID, this.configID$, data).subscribe(res => {
      if (res.success) {
        this.notifierService.notify('success', 'Aggiornato con successo!');
        return;
      }
      this.notifierService.notify('error', 'Non può aggiornare!');
    });

  }

  private updateConfig(data) {

    this._adminService.updateConfigName(this.locationID$, this.configID$, data).subscribe(res => {
      if (res.success) {
        this.notifierService.notify('success', 'Aggiornato con successo!');
        return;
      }
      this.notifierService.notify('error', 'Non può aggiornare!');
    });
  }

  //Timetable
  deleteTimeTable(courseID, timeSlotID, courseIndex, timeSlotIndex) {
    if (!courseID || !timeSlotID) {
      this.notifierService.notify('error', 'Dati non validi!');
      return;
    }

    this._adminService.deleteTimeTableConfiguration(courseID, timeSlotID).subscribe(res => {

      if (res.success) {
        this.configData.course_configurations[courseIndex].time_slot_configurations.splice(timeSlotIndex, 1);
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }

    });
  }

  addNewTimeSlot(event, form, courseID, courseIndex) {

    if (form.invalid) {
      this.notifierService.notify('error', 'Dati non validi!');
      return;
    }

    this._adminService.addNewTimeSlotConfiguration(courseID, form.value).subscribe(res => {

      if (res.success) {
        //form.reset();
        this.resetForm(form);
        this.configData.course_configurations[courseIndex].time_slot_configurations.push(res.data.time_slot_configurations);
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }

    });

  }

  updateTimeSlot(courseID, timeSlotID, timeSlotIndex, timeSlotValue, dayProp, checkboxID, courseIndex) {
    let val = '.courseBlock_' + courseIndex;
    let div = jQuery(val);
    let current_input = div.find("input[id='" + checkboxID +"']");
    current_input[0].setAttribute("data-flag", false);

    if (!timeSlotValue.value || timeSlotValue.value === '' || !isInt(timeSlotValue.value)) {
      timeSlotValue.classList.add('is-invalid');
      this.notifierService.notify('error', 'Dati non validi!');
      return;
    }

    if (!timeSlotID || timeSlotID === '') {
      this.notifierService.notify('error', 'Dati non validi!');
      return;
    }

    const _obj = {
      time_slot_configuration: {
        [dayProp]: timeSlotValue.value
      }
    };

    this._adminService.updateTimeSlot(courseID, timeSlotID, _obj).subscribe(res => {
      if (res.success) {
        timeSlotValue.classList.remove('is-invalid');
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }
    });

  }

  changeColumnValue(event,courseIndex, index){
    event.target.dataset.flag = true;
    let val = '.courseBlock_' + courseIndex;
    let div = jQuery(val);
    if(div.length > 0){
      let blocks = div.find("div[data-row="+index+"]");
      for(let block of blocks){
        let input = jQuery(block).find("input");
        if(!event.target.checked){
          input.prop("disabled", true);
        }else{
          input.prop("disabled", false);
        }
      }
    }

  }

  checkTimeSlot(courseIndex, index, id){
    let val = '.courseBlock_' + courseIndex;
    let div = jQuery(val);
    let current_input = div.find("input[id='" + id +"']");
    if(current_input && current_input[0] && current_input[0].getAttribute("data-flag") == 'true'){
      return current_input[0].checked;
    }
    if(div.length > 0){
      let blocks = div.find("div[data-row="+index+"]");
      let flag = false;
      for(let block of blocks){
        let input = jQuery(block).find("input");
          if(input && input.val() > 0){
            flag = true;
          }
      }
      if(!flag){
        jQuery(blocks).find("input").prop("disabled", true);
        return false;
      }
      return true;
    }
    return false
  }

  disableTimeSlot(ev, courseIndex) {
    const inputEle = jQuery(ev.target),
      rowID = inputEle.attr('data-row');

    if (!rowID || rowID === '') {
      this.notifierService.notify('success', 'Dati ID non validi!');
      return;
    }

    const slotRow = jQuery('.courseBlock_' + courseIndex + ' .slotRowItem[data-row=' + rowID + ']');

    if (slotRow) {
      slotRow.toggleClass('disabled');
      slotRow.find('input').toggleClass('disabled');
      slotRow.find('.save_btn').toggleClass('disabled');

      slotRow.find('input').attr('disabled', function (_, attr) {
        return !attr;
      });
      slotRow.find('.save_btn').attr('disabled', function (_, attr) {
        return !attr;
      });
    }

  }

  private getConfigurationsAPI(locationID: string) {

    this.isLoading = true;
    this._adminService.getConfigByLocation(locationID).subscribe(res => {
      this.isLoading = false;
      if (res.success) {
        this.tickets = res.data.tickets;
      }
    });

  }

}
