import { Component, OnInit, ViewChild } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { AdminService } from "../../services/admin.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  IMyDate,
  IMyDateModel,
  IMyDpOptions,
} from "../../../my-date-picker/interfaces";
import { MyDatePicker } from "../../../my-date-picker";
import { NotifierService } from "angular-notifier";
import * as moment from "moment";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { SharedService } from "../../services/shared.service";
import { ActivatedRoute } from "@angular/router";

export enum DISCOUNT_TYPOLOGY {
  PROMOTION = "promozione",
  CODE = "codice_sconto",
}

@Component({
  selector: "app-promo-discount",
  templateUrl: "./promo-discount.component.html",
  styleUrls: ["./promo-discount.component.scss"],
})
export class PromoDiscountComponent implements OnInit {
  discountsData: any = [];
  public typology: DISCOUNT_TYPOLOGY;
  public typologies = DISCOUNT_TYPOLOGY;

  isLoading: boolean = false;

  locations: Array<any> = [];

  selectedLocation: { id: string; name: string } = { id: "", name: "" };

  discountForm: FormGroup;

  isSubmitted: boolean = false;

  typologyError: boolean = false;

  private date: Date = new Date();

  todayDate: any = { year: 0, month: 0, day: 0 };

  private unsubscribe$: Subject<void> = new Subject();

  myDatePickerInlineOptions: IMyDpOptions = {
    dateFormat: "dd/mm/yyyy",
    minYear: this.date.getFullYear() - 1,
    maxYear: this.date.getDate(),
    monthLabels: {
      1: "Gennaio",
      2: "Febbraio",
      3: "Marzo",
      4: "Aprile",
      5: "Maggio",
      6: "Giugno",
      7: "Luglio",
      8: "Agosto",
      9: "Settembre",
      10: "Ottobre",
      11: "Novembre",
      12: "Dicembre",
    },
    disableUntil: {
      year: this.date.getFullYear(),
      month: this.date.getMonth() + 1,
      day: this.date.getDate() - 1,
    },
    disableDays: [{ year: 0, month: 0, day: 0 }],
    // disableSince: {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() + 1},
    selectorHeight: "auto",
    selectorWidth: "410px",
    allowSelectionOnlyInCurrentMonth: false,
    disableHeaderButtons: true,
    yearSelector: false,
    monthSelector: false,
    showTodayBtn: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: false,
    editableDateField: false,
    showSelectorArrow: false,
    inline: false,
  };

  localeIt: any = "it";

  promoTypes: Array<{ id; disabled; val }> = [
    {
      id: "promo_1",
      disabled: false,
      val: 1,
    },
    {
      id: "promo_2",
      disabled: true,
      val: 2,
    },
    {
      id: "promo_3",
      disabled: true,
      val: 3,
    },
  ];

  selectedPromoType: number;

  constructor(
    private _adminService: AdminService,
    private _sharedService: SharedService,
    public ngxSmartModalService: NgxSmartModalService,
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private route: ActivatedRoute
  ) {
    this.typology = route.snapshot.data.typology;
    this.todayDate = {
      year: this.date.getFullYear(),
      month: this.date.getMonth() + 1,
      day: this.date.getDate(),
    };
    this.selectedPromoType =
      this.typology === DISCOUNT_TYPOLOGY.PROMOTION ? 1 : 2;
  }

  ngOnInit() {
    //Locations
    this._sharedService.getLocation.subscribe((location) => {
      this.locations = location;
      // this.selectedLocation = {
      //   id: this.locations[0].id,
      //   name: this.locations[0].name,
      // };
    });

    // == Get Discounts
    this.isLoading = true;

    this._adminService.getPromotions(this.typology).subscribe((res) => {
      this.discountsData = res.success ? res.data.promotions : null;
      this.isLoading = false;
    });

    // == Form Builder
    this.setForm();
  }

  //DISCOUNT LISTS
  deletePromotion(id: string, index) {
    if (!id || id === "") {
      this.notifierService.notify("error", "ID non presente!");
      return;
    }

    this._adminService.deletePromotion(id).subscribe((res) => {
      if (res.success) {
        this.discountsData.splice(index, 1);
        this.notifierService.notify("success", "Aggiornato con successo!");
      }
    });
  }

  // NEW DISCOUNT
  get discountControls() {
    return this.discountForm.controls;
  }

  changeLocation(e) {
    this.discountControls.locationInput.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  changeTicketType(e) {
    this.discountControls.ticketTypeInput.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  openPromoModal() {
    this.ngxSmartModalService.setModalData({}, "promoDiscountModal");
    this.ngxSmartModalService.open("promoDiscountModal");
  }

  onDateChanged(event: IMyDateModel, type: string) {
    console.log(event.formatted, type);
  }

  typeChange(event, promoID) {
    if (!promoID) {
      return;
    }

    switch (promoID) {
      case "promo_1":
        this.discountControls.tipologiaOne.enable();
        this.discountControls.tipologiaTwo.disable();
        this.discountControls.tipologiaThree.disable();
        break;
      case "promo_2":
        this.discountControls.tipologiaTwo.enable();
        this.typology === DISCOUNT_TYPOLOGY.PROMOTION &&
          this.discountControls.tipologiaOne.disable();
        this.discountControls.tipologiaThree.disable();
        break;
      case "promo_3":
        this.discountControls.tipologiaThree.enable();
        this.typology === DISCOUNT_TYPOLOGY.PROMOTION &&
          this.discountControls.tipologiaOne.disable();
        this.discountControls.tipologiaTwo.disable();
        break;
      default:
        this.typology === DISCOUNT_TYPOLOGY.PROMOTION &&
          this.discountControls.tipologiaOne.enable();
        this.discountControls.tipologiaTwo.disable();
        this.discountControls.tipologiaThree.disable();
    }

    this.promoTypes.filter((item, i) => {
      if (item.id === promoID) {
        this.selectedPromoType = item.val;
      }
      this.promoTypes[i].disabled = item.id !== promoID;
    });
  }

  setForm(){
    if (this.typology === DISCOUNT_TYPOLOGY.PROMOTION)
    this.discountForm = this.formBuilder.group({
      locationInput: [""],
      orderDateFrom: [this.todayDate, Validators.required],
      orderDateTo: [this.todayDate, Validators.required],
      prenotaDateFrom: [this.todayDate, Validators.required],
      prenotaDateTo: [this.todayDate, Validators.required],
      tipologia: [this.promoTypes[0].id, Validators.required],
      tipologiaOne: [{ value: "", disabled: false }, Validators.required],
      tipologiaTwo: [{ value: "", disabled: true }, Validators.required],
      tipologiaThree: [{ value: "", disabled: true }, Validators.required],
      ticketTypeInput: ["", Validators.required],
    });
  else
    this.discountForm = this.formBuilder.group({
      locationInput: [""],
      codeName: ["", Validators.required],
      orderDateFrom: [this.todayDate, Validators.required],
      orderDateTo: [this.todayDate, Validators.required],
      tipologia: [this.promoTypes[1].id, Validators.required],
      tipologiaTwo: [{ value: "", disabled: false }],
      tipologiaThree: [{ value: "", disabled: true }],
      ticketTypeInput: ["", Validators.required],
    });
  }

  submitDiscount() {
    this.isSubmitted = this.isLoading = true;

    if (!this.discountForm.valid) {
      return false;
    }

    //create post data _obj
    const formValue = this.discountForm.value;

    let _obj = {};

    //format datepicker
    let from_date = moment
        .unix(formValue.orderDateFrom.epoc)
        .format("DD/MM/YYYY"),
      to_date = moment.unix(formValue.orderDateTo.epoc).format("DD/MM/YYYY");
    let booking_from_date: any;
    let booking_to_date: any;
    if (this.typology === DISCOUNT_TYPOLOGY.PROMOTION) {
      booking_from_date = moment
        .unix(formValue.prenotaDateFrom.epoc)
        .format("DD/MM/YYYY");
      booking_to_date = moment
        .unix(formValue.prenotaDateTo.epoc)
        .format("DD/MM/YYYY");
    }

    //create obj by promo_type
    let tempPrice: any;
    if (this.selectedPromoType === 1) {
      if (formValue.tipologiaOne === "") {
        this.typologyError = true;
        this.isLoading = false;
        return false;
      }
      tempPrice = formValue.tipologiaOne;
    } else if (this.selectedPromoType === 2) {
      if (formValue.tipologiaTwo === "") {
        this.typologyError = true;
        this.isLoading = false;
        return false;
      }
      tempPrice = formValue.tipologiaTwo;
    } else if (this.selectedPromoType === 3) {
      if (formValue.tipologiaThree === "") {
        this.typologyError = true;
        this.isLoading = false;
        return false;
      }
      tempPrice = formValue.tipologiaThree;
    }
    _obj = {
      promotion: {
        location_id: formValue.locationInput != "" ? formValue.locationInput : null,
        from_date: from_date,
        to_date: to_date,
        promo_type: this.selectedPromoType,
       // price: tempPrice,
      },
    };

    if (this.typology === DISCOUNT_TYPOLOGY.PROMOTION) {
      _obj["promotion"]["booking_from_date"] = booking_from_date;
      _obj["promotion"]["booking_to_date"] = booking_to_date;
      _obj["promotion"]["ticket_type"] = formValue.ticketTypeInput;
      if(this.selectedPromoType != 1){
        _obj["promotion"]["discount"] = tempPrice;
      }else{
        _obj["promotion"]["price"] = tempPrice;
      }
    } else {
      _obj["promotion"]["name"] = formValue.codeName.toUpperCase();
      _obj["promotion"]["discount"] = tempPrice;
      _obj["promotion"]["ticket_type"] = formValue.ticketTypeInput;
    }

    //Send Promo
    this._adminService
      .addPromotion(_obj)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.isLoading = false;
        this.discountsData.unshift(res.data.promotion);
        this.ngxSmartModalService.close("promoDiscountModal");
        this.discountForm.reset();
      });
  }
}
