import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { Observable, Subject, Subscribable, Subscription } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { UploadsService } from '../../services/uploads.service';
import { NotifierService } from 'angular-notifier';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-promo-banner',
  templateUrl: './promo-banner.component.html',
  styleUrls: ['./promo-banner.component.scss']
})
export class PromoBannerComponent implements OnInit, OnDestroy {

  bannerData: Array<any> = [];

  isLoading: boolean = false;
  isUploading: boolean = false;
  isSubmitted: boolean = false;

  private unsubscribe$: Subscription = new Subscription();

  selectedField: any;
  selectedBanner: any;
  bannerForm: FormGroup;

  constructor(
    private _adminService: AdminService,
    private _uploadService: UploadsService,
    private notifierService: NotifierService,
    public ngxSmartModalService: NgxSmartModalService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit() {
    this.getBanners();
    this.setForm();
  }

  getBanners() {
    this.isLoading = true;
    this.unsubscribe$ = this._adminService.getBanners().subscribe(res => {
      this.bannerData = res.success ? res.data.banners : null;
      this.isLoading = false;
    });
  }

  updateBannerEvent(event, type) {
    let _obj = {
      banner: {},
      banner_type: type
    };
    this.isUploading = true;
    this._uploadService.uploadFile(event, this.selectedBanner.id, 'Banner', type).subscribe(res => {
      console.log('end:', res);
      if (res && res.url) {
        if (type == 'header') {
          this.bannerControls.image_url.setValue(res.url);
        }
        else if (type == 'footer_up') {
          this.bannerControls.image_foot_up_url.setValue(res.url);
        }
        else if (type == 'footer_down') {
          this.bannerControls.image_foot_down_url.setValue(res.url);
        }
        this.selectedField = null;
        this.notifierService.notify('success', 'Immagine aggiornata con successo!');
      } else {
        this.notifierService.notify('error', 'Errore nel caricamento immagine');
      }
      this.isUploading = false;

    });
    return;
  }

  removeImage(type){
    if (type == 'header') {
      if(this.selectedBanner.image){
        this.deleteImageApi(this.selectedBanner.image.id);
      }
      this.bannerControls.image_url.setValue(null);
    }
    else if (type == 'footer_up') {
      if(this.selectedBanner.image_foot_up){
        this.deleteImageApi(this.selectedBanner.image_foot_up.id);
      }
      this.bannerControls.image_foot_up_url.setValue(null);
    }
    else if (type == 'footer_down') {
      if(this.selectedBanner.image_foot_down){
        this.deleteImageApi(this.selectedBanner.image_foot_down.id);
      }
      this.bannerControls.image_foot_down_url.setValue(null);
    }
  }

  deleteImageApi(img_id){
    this._uploadService.deleteFile(img_id).subscribe(res => {
    })
  }

  setForm() {
    this.bannerForm = this.formBuilder.group({
      name: ["", Validators.required],
      image_url: [""],
      link: [""],
      image_foot_up_url: [""],
      link_foot_up: [""],
      image_foot_down_url: [""],
      link_foot_down: [""]
    })
  }

  get bannerControls() {
    return this.bannerForm.controls;
  }

  editField(field, type) {
    this.selectedField = !this.selectedField
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  openModal(banner) {
    this.selectedBanner = banner;
    if (banner.location) {
      this.bannerControls.name.setValue(banner.location.name);
      this.bannerControls.image_url.setValue(banner.image_url);
      this.bannerControls.link.setValue(banner.link);
      this.bannerControls.image_foot_up_url.setValue(banner.image_foot_up_url);
      this.bannerControls.link_foot_up.setValue(banner.link_foot_up);
      this.bannerControls.image_foot_down_url.setValue(banner.image_foot_down_url);
      this.bannerControls.link_foot_down.setValue(banner.link_foot_down);
    }
    this.ngxSmartModalService.setModalData({}, "bannerModal");
    this.ngxSmartModalService.open("bannerModal");
  }


  submitForm() {
    this.isSubmitted = true;
    let _obj = {
      banner: {},
    };

    _obj.banner['name'] = this.bannerControls.name.value;
    _obj.banner['link'] = this.bannerControls.link.value;
    _obj.banner['link_foot_up'] = this.bannerControls.link_foot_up.value;
    _obj.banner['link_foot_down'] = this.bannerControls.link_foot_down.value;
    _obj.banner['image_url'] = this.bannerControls.image_url.value;
    _obj.banner['image_foot_up_url'] = this.bannerControls.image_foot_up_url.value;
    _obj.banner['image_foot_down_url'] = this.bannerControls.image_foot_down_url.value;
    this._adminService.updateBanner(_obj, this.selectedBanner.id).subscribe(res => {
      if (res.success) {
        this.notifierService.notify('success', 'Banner modificato con successo!');
        this.ngxSmartModalService.close("bannerModal");
        this.getBanners();
        this.setForm();
      }
    });
  }

}
