import { Guide } from './guide.model';

export class DateConfiguration {
  id = '';
  created_at: number;
  updated_at: number;
  date_event = '';
  start_at = '';
  end_at = '';
  max_seats: number;
  min_seats: number;
  expire_at = '';
  guide: Guide = new Guide();
  from_date = '';
  to_date = '';
}
export class Event {
id = '';
created_at: number;
updated_at: number;
status = '';
available_tickets: number;
percentage_completion_tickets = 0;
tickets_to_confirm = 1;
date_configuration: DateConfiguration = new DateConfiguration();;
}
