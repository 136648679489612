import {Component, OnInit, ViewChild} from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {AngularTokenService} from 'angular-token';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('loginForm') loginForm;

  loginData: any = {};

  isLoading = false;

  private notifier: NotifierService;

  constructor(
    private tokenService: AngularTokenService,
    private _authService: AuthService,
    private router: Router,
    private notifierService: NotifierService) {
    this._authService.redirectAuth();
  }

  ngOnInit() {
    this.notifier = this.notifierService;
  }

  signIn() {

    this.isLoading = true;

    const data = {
      login: this.loginData.email,
      password: this.loginData.password
    };

    this.tokenService.signIn(data).subscribe(
      res => {
        this.isLoading = false;
        this.loginForm.resetForm();
        localStorage.setItem('userData', JSON.stringify(res.body.user));
        this.router.navigateByUrl('/admin');
      },
      error => {
        this.isLoading = false;
        this.loginForm.resetForm();
        this.notifier.notify('error', 'Credenziali non valide, controlla i dati inseriti');
      }
    );
  }
}
