import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { NotifierService } from "angular-notifier";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  private headers: HttpHeaders;
  notifier: NotifierService;

  private API_URL = environment.API;
  constructor(
    private _http: HttpClient,
    private _notifierService: NotifierService
  ) {
    this.notifier = this._notifierService;
    this.headers = new HttpHeaders().set("Content-Type", "application/json");
    // const userData = JSON.parse(localStorage.getItem('userData'));
    this.headers.append("access-token", localStorage.getItem("accessToken"));
    console.log(this.headers);
    this.headers.append("client", localStorage.getItem("client"));
    this.headers.append("token-type", localStorage.getItem("tokenType"));
    this.headers.append("uid", localStorage.getItem("uid"));
  }

  // == Orders
  getOrders(
    attraction_id: string,
    from_date: string,
    to_date: string,
    filters,
    page: number,
    per_page: number
  ): Observable<any> {
    var url = "admin/orders";
    if (attraction_id) {
      url += "?attraction_id=" + attraction_id;
      if (from_date) {
        url += "&date_from=" + from_date;
      }
      if (from_date) {
        url += "&date_to=" + to_date;
      }
    } else {
      if (from_date) {
        url += "?date_from=" + from_date;
        if (to_date) {
          url += "&date_to=" + to_date;
        }
      } else {
        url += "?date_to=" + to_date;
      }
    }
    url +=
      (filters && filters["guide_id"]
        ? "&guide_id=" + filters["guide_id"]
        : "") +
      (filters && filters["order_status"]
        ? "&order_status=" + filters["order_status"]
        : "") +
      (filters && filters["event_status"]
        ? "&event_status=" + filters["event_status"]
        : "");

    url +="&page=" + page + "&per_page=" + per_page;

    return this._http.get(this.API_URL + url).pipe(
      map((res: any) => {
        return {
          success: true,
          data: res,
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify("error", error.error.errors[0]);
        } else {
          this.notifier.notify("error", "Si è verificato un errore!");
        }
        return of(result as T);
      })
    );
  }
  deleteOrder(order) {
    return this._http.delete(this.API_URL + `admin/orders/${order.id}`).pipe(
      map((res: any) => {
        return {
          success: true,
          data: res,
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify("error", error.error.errors[0]);
        } else {
          this.notifier.notify("error", "Si è verificato un errore!");
        }
        return of(result as T);
      })
    );
  }
  getOrdersByDate(attraction_id, date, filters) {
    let url =
      this.API_URL +
      `admin/orders?attraction_id=${attraction_id}&date=${date}` +
      (filters && filters["guide_id"]
        ? "&guide_id=" + filters["guide_id"]
        : "") +
      (filters && filters["order_status"]
        ? "&order_status=" + filters["order_status"]
        : "") +
      (filters && filters["event_status"]
        ? "&event_status=" + filters["event_status"]
        : "");
    return this._http.get(url).pipe(
      map((res: any) => {
        return {
          success: true,
          data: res,
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify("error", error.error.errors[0]);
        } else {
          this.notifier.notify("error", "Si è verificato un errore!");
        }
        return of(result as T);
      })
    );
  }
  getCsv(params: Array<string> = []) {
    var url = `${this.API_URL}admin/orders?format=csv${
      params && params.length > 0 ? `&${params.join("&")}` : ""
    }`;
    return this._http.get(url, { responseType: "blob" }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify("error", error.error.errors[0]);
        } else {
          this.notifier.notify("error", "Si è verificato un errore!");
        }
        return of(result as T);
      })
    );
  }

  cancelReservation(order_id, res_id) {
    return this._http
      .delete(this.API_URL + `admin/orders/${order_id}/events/${res_id}/cancel`)
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.notifier.notify("error", error.error.errors[0]);
          } else {
            this.notifier.notify("error", "Si è verificato un errore!");
          }
          return of(result as T);
        })
      );
  }

  openPDF(order_id, res_id) {
    return this._http
      .get(
        this.API_URL + `admin/orders/${order_id}/events/${res_id}/download`,
        { responseType: "blob" }
      )
      .subscribe((data) => {
        var file = new Blob([data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
  }
}
