import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[appDropdown]',
  exportAs: 'appDropdown'
})
export class DropdownDirective {

  constructor() {
  }

  @HostListener('click', ['$event']) toggleOpen($event) {
    $event.stopPropagation();
    $event.target.parentElement.classList.toggle('open');
  }

}
