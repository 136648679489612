import { Component, OnInit, ViewChildren, ChangeDetectorRef, KeyValueDiffers } from '@angular/core';
import { IMyDpOptions, IMyDateModel, IMyDate } from 'src/my-date-picker';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { SharedService } from 'src/app/services/shared.service';
import { NotifierService } from 'angular-notifier';
import { isInt } from 'src/app/helpers/helpers';
import * as jQuery from 'jquery';
import { AttrazioniService } from 'src/app/services/attrazioni.service';
import { CalendarioService } from 'src/app/services/calendario.service';
import { Event } from 'src/app/Models/Event.model';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { GuideService } from 'src/app/services/guide.service';
import { Guide } from 'src/app/Models/guide.model';
import { TranslationWidth } from '@angular/common';
import { THROW_IF_NOT_FOUND } from '@angular/core/src/di/injector';
import { getFirstDayPreviousMonth } from '../../helpers/helpers';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss']
})
export class CalendarioComponent implements OnInit {

  events = new Array<Event>();
  newEvent = new Event();
  guides = new Array<Guide>();

  date: Date = new Date();

  isEdit = false;
  imDate: IMyDate = { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() };


  locations: Array<any> = [];
  calendarForm: FormGroup;
  selectedLocation: { id: string, name: string } = { id: '', name: '' };

  selectedDate: string = moment().format('YYYY-MM-DD');

  isLoading = false;

  private $subscriptionCalendar: Subscription;

  calendarData: any = null;

  formMyDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    monthLabels: {
      1: 'Gennaio',
      2: 'Febbraio',
      3: 'Marzo',
      4: 'Aprile',
      5: 'Maggio',
      6: 'Giugno',
      7: 'Luglio',
      8: 'Agosto',
      9: 'Settembre',
      10: 'Ottobre',
      11: 'Novembre',
      12: 'Dicembre'
    },
    dayLabels: {
      su: 'Dom', mo: 'Lun ', tu: 'Mar', we: 'Mer', th: 'Gio', fr: 'Ven', sa: 'Sab'
    },
    sunHighlight: false,
    selectorHeight: 'auto',
    selectorWidth: '410px',
    allowSelectionOnlyInCurrentMonth: false,
    disableHeaderButtons: true,
    yearSelector: false,
    monthSelector: false,
    showTodayBtn: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: false,
    editableDateField: false,
    showSelectorArrow: false,
    inline: false
  }


  localeIt: any = 'it';

  todayDate: { year: number, month: number, day: number } = { year: 0, month: 0, day: 0 };
  currentDate: { year: number, month: number, day: number } = { year: 0, month: 0, day: 0 };

  numbers: Array<object> = [];

  // config list
  configCoursesArray: Array<any> = [];

  selectedCourseField: any = null;

  weekDayNumber: number;

  weekDayLabel: string;

  current_location: string = "";

  clone: boolean = false;

  month: any;
  year: any;
  day: any;

  @ViewChildren('editForm') editForm;
  date_event: any;
  fromDate: any = { year: this.date.getFullYear(), month: this.date.getMonth(), day: this.date.getDate() };
  toDate: any = { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() };
  expire_at: any = { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() };

  btnDisabled = false;
  constructor(
    private _adminService: AdminService,
    private _sharedService: SharedService,
    private notifierService: NotifierService,
    private attractionService: AttrazioniService,
    private calendarioService: CalendarioService,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private modalService: NgxSmartModalService,
    private guideService: GuideService,
    private cdr: ChangeDetectorRef
  ) {
    this.todayDate = { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() };
  }

  ngOnInit() {

    this.weekDayNumber = moment(this.selectedDate).day();
    this.weekDayLabel = this.getWeekDayName(this.selectedDate).toUpperCase();


    this.guideService.getGuides().subscribe((resp: any) => {
      this.guides = resp.data.guides;
    });
    this.month = this.todayDate.month;
    this.year = this.todayDate.year;
    this.day = this.todayDate.day;
    this.currentDate = this.todayDate;
    this.getAttractions();
    this.calendarForm = this.fb.group({
      attraction: ['', Validators.required],
      from_date: [''],
      to_date: ['']
    });
  }

  ngAfterViewInit() {
    this.setDate();
    this.cdr.detectChanges();
  }

  setDate() {
    let date = new Date();
    let prevDate = getFirstDayPreviousMonth();
    this.calendarForm.patchValue({
      from_date: {
        date: {
          year: prevDate.getFullYear(),
          month: prevDate.getMonth() + 1,
          day: prevDate.getDate()
        }
      }
    });
    this.calendarForm.patchValue({
      to_date: {
        date: {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        }
      }
    });
    this.fromDate = {
      year: prevDate.getFullYear(),
      month: prevDate.getMonth() + 1,
      day: prevDate.getDate()
    }
    this.toDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    }
    this.cdr.detectChanges();
  }

  getAttractions() {
    this.attractionService.getAttractions().subscribe((resp: any) => {
      if (resp && resp.success) {
        this.locations = resp.data.attractions;
        this.getCalendarApi(this.current_location);
      }
    });
  }
  addEvent(form: NgForm) {
    const ev = new Event();
    const controls = form.controls;
    ev.date_configuration.date_event = controls.date_event.value.formatted;
    ev.date_configuration.min_seats = this.newEvent.date_configuration.min_seats;
    ev.date_configuration.max_seats = this.newEvent.date_configuration.max_seats;
    ev.date_configuration.start_at = this.newEvent.date_configuration.start_at;
    ev.date_configuration.end_at = this.newEvent.date_configuration.end_at;
    ev.date_configuration.expire_at = controls.expire_at.value.formatted
    // ev.date_configuration.guide = this.guides.find(g => g.id == controls.guide.value);
    if (this.isEdit) {
      const d_config = ev.date_configuration;
      const event = {
        id: this.newEvent.id, attraction_id: this.selectedLocation.id, date_configuration: {
          start_at: d_config.start_at, end_at: d_config.end_at, date_event: d_config.date_event, expire_at: d_config.expire_at,
          max_seats: d_config.max_seats, min_seats: d_config.min_seats,
          guide_id: controls.guide.value
        }
      };
      this.calendarioService.editEvents(event).subscribe((res: any) => {
        if (res && res.success) {
          this.isEdit = false;
          this.getAttractions();
          this.clone = false;
          this.modalService.close('eventAddModal');
        }
      });
      return;
    }
    this.calendarioService.createEvents(this.selectedLocation.id, ev, controls.guide.value).subscribe((res: any) => {
      if (res && res.success) {
        this.modalService.close('eventAddModal');
        this.clone = false;
        this.getAttractions();
      }
    });
  }
  openModal() {
    this.isEdit = false;
    this.clone = false;
    this.newEvent = new Event();
    this.modalService.open('eventAddModal');
  }
  delete(event) {
    this.calendarioService.deleteEvents(event).subscribe((resp: any) => {
      if (resp.success) {
        this.getCalendarApi(this.current_location);
        //this.getAvailabilityOfMonth(this.current_location);
      }
    });
  }
  cloneEvent(event) {
    this.clone = true;
    this.newEvent = event;
    this.current_location = this.newEvent["attraction_id"];
    const d = new Date(this.newEvent.date_configuration.date_event);
    this.date_event = {
      month: d.getMonth() + 1,
      day: d.getDate(),
      year: d.getFullYear()
    };
    const d2 = new Date(this.newEvent.date_configuration.expire_at);
    this.expire_at = {
      month: d2.getMonth() + 1,
      day: d2.getDate(),
      year: d2.getFullYear()
    };

    this.modalService.open('eventAddModal');
  }

  edit(event) {
    this.isEdit = true;
    this.clone = false;
    this.newEvent = event;
    this.current_location = this.newEvent["attraction_id"];
    this.selectedLocation.id = this.newEvent["attraction_id"];
    const d = new Date(this.newEvent.date_configuration.date_event);
    this.date_event = {
      month: d.getMonth() + 1,
      day: d.getDate(),
      year: d.getFullYear()
    };
    const d2 = new Date(this.newEvent.date_configuration.expire_at);
    this.expire_at = {
      month: d2.getMonth() + 1,
      day: d2.getDate(),
      year: d2.getFullYear()
    };
    this.modalService.open('eventAddModal');
  }
  changeLocation(ev) {
    this.selectedLocation = {
      id: ev.target.value,
      name: ev.target.options[ev.target.selectedIndex].getAttribute('name')
    };
    localStorage.setItem('current_location_revelia', ev.target.value);
    const i = this.locations.findIndex(e => e.id == this.selectedLocation.id);
    this.btnDisabled = !this.locations[i].online;
    // update calendar Data => Location
    this.getCalendarApi(ev.target.value);
    console.log(this.selectedLocation.id);
    // update config Data => Location
    // this.getConfigData();
  }

  onDateChanged(event: IMyDateModel) {
    this.fromDate = event.date;
    this.getCalendarApi(this.selectedLocation.id);
  }

  onToDateChangedTo(event: IMyDateModel) {
    this.toDate = event.date;
    this.getCalendarApi(this.selectedLocation.id);
  }

  openEditRowEv(ev, courseIndex, ticket, ticketIndex) {

    let parent = ev.target.parentElement.parentElement.parentElement.parentElement;

    if (ev.target.classList.contains('fas')) {
      parent = ev.target.parentElement.parentElement.parentElement.parentElement.parentElement;
    }

    if (!parent.classList.contains('open-edit-row')) {
      parent.classList.toggle('open-edit-row');
    }

    const editFORM = this.editForm._results[courseIndex];

    // fill form rows
    editFORM.controls.name.setValue(ticket.name);
    editFORM.controls.description.setValue(ticket.description);
    editFORM.controls.price.setValue(ticket.price);
    editFORM.controls.ticketID.setValue(ticket.id);
    editFORM.controls.ticketIndex.setValue(ticketIndex);
  }

  closeEditRow(ev, courseIndex) {
    let parent = ev.target.parentElement.parentElement.parentElement.parentElement;

    if (ev.target.classList.contains('fas')) {
      parent = ev.target.parentElement.parentElement.parentElement.parentElement.parentElement;
    }

    parent.classList.remove('open-edit-row');

    // reset edit form
    this.editForm._results[courseIndex].reset();

  }

  openNewRowEv(ev) {
    ev.target.parentElement.parentElement.parentElement.parentElement.classList.toggle('open-new-row');
  }

  resetFilter() {
    this.current_location = "";
    this.selectedLocation = {
      id: "",
      name: ""
    };
    this.setDate();
    this.getCalendarApi("");
  }

  overbooking(value) {
    let val = value + "";
    if (value <= 0) {
      return val.substring(1);
    }
    return val;
  }


  // getAvailabilityOfMonth(locationId: string): void {
  //   this.isLoading = true;
  //   this.$subscriptionCalendar = this.calendarioService.getCalendar(locationId, this.month, this.year, null)
  //     .subscribe(res => {
  //       this.isLoading = false;
  //       console.log(res);
  //       if (!res.success) {
  //         return;
  //       }
  //       this.calendarData = res.data;
  //       //this.events = res.data.calendar.events;
  //       this.disableDays(this.calendarData.calendar.dates);
  //     });
  // }

  /**
   *
   * @param locationId
   */
  private getCalendarApi(locationId: string): void {
    this.isLoading = true;
    var from = "";
    var to = "";
    if (this.fromDate) {
      from = this.fromDate['year'] + '-' + this.fromDate['month'] + '-' + this.fromDate['day'];
    }
    if (this.toDate) {
      to = this.toDate['year'] + '-' + this.toDate['month'] + '-' + this.toDate['day'];
    }
    this.$subscriptionCalendar = this.calendarioService.getCalendar(locationId, from, to)
      .subscribe(res => {
        this.isLoading = false;
        console.log(res);
        if (!res.success) {
          return;
        }
        this.calendarData = res.data.calendar;
        // this.disableDays(this.calendarData.calendar.dates);
      });
  }

  private getConfigData(): void {
    this.isLoading = true;
    const d = new Date(this.selectedDate);
    this._adminService.getConfigDataCalendar(this.selectedLocation.id, this.selectedDate).subscribe(res => {
      this.isLoading = false;
      if (res.success) {
      }
    });
  }

  onCalendarViewChanged(event) {
    if (!this.selectedLocation.id) return;
    this.month = event.month;
    this.year = event.year;
    if (this.month == this.todayDate.month) {
      this.day = this.todayDate.day;
    } else {
      this.day = 1;
    }

    this.currentDate = { year: this.year, month: this.month, day: this.day };
    // this.getAvailabilityOfMonth(this.selectedLocation.id);
    this.getCalendarApi(this.selectedLocation.id);
  }


  // Courses
  editCourseField(field, type) {
    this.selectedCourseField = {
      field,
      type
    };
  }

  coursePublicEvent(event, courseID) {
    const _obj = {
      course: {
        public: event.target.checked
      }
    };

    this.updateCourse(courseID, _obj);
  }

  updateCourseName(event, course, index) {

    const _obj = {
      course: {
        name: event
      }
    };

    this.selectedCourseField = null;

    this.configCoursesArray[index].name = event;

    this.updateCourse(course.id, _obj);
  }

  deleteCourse(courseID, courseIndex) {

    if (!courseID) {
      this.notifierService.notify('error', 'Dati non validi!');
      return;
    }

    this._adminService.deleteCalendarCourse(this.selectedLocation.id, courseID).subscribe(res => {

      if (res.success) {
        this.configCoursesArray.splice(courseIndex, 1);
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }

    });
  }

  /**
   *
   * @param courseID
   * @param data
   */
  private updateCourse(courseID, data) {

    this._adminService.updateCalendarCourse(this.selectedLocation.id, courseID, data).subscribe(res => {
      if (res.success) {
        this.notifierService.notify('success', 'Aggiornato con successo!');
        return;
      }
      this.notifierService.notify('error', 'Non può aggiornare!');
    });

  }

  // Tickets
  ticketPublicEvent(event, courseID, ticketID, courseIndex, ticketIndex) {
    const _obj = {
      ticket: {
        public: event.target.checked
      }
    };

    this.updateTicket(courseID, ticketID, courseIndex, ticketIndex, _obj, null, event);
  }

  deleteTicket(courseID, ticketID, courseIndex, ticketIndex) {

    if (!courseID || !ticketID) {
      this.notifierService.notify('error', 'Dati non validi!');
      return;
    }

    this._adminService.deleteCalendarTicket(courseID, ticketID).subscribe(res => {

      if (res.success) {
        this.configCoursesArray[courseIndex].ticket_configurations.splice(ticketIndex, 1);
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }

    });
  }

  editTicket(event, form, courseID, courseIndex) {

    if (form.invalid) {
      return;
    }

    const optionalObj = {
      type: 'edit'
    };

    const _objUpdate = {
      ticket: {
        description: form.value.description,
        name: form.value.name,
        price: form.value.price
      }
    };

    this.updateTicket(courseID, form.value.ticketID, courseIndex, form.value.ticketIndex, _objUpdate, optionalObj, event);
  }

  addNewTicket(event, form, courseID, courseIndex) {

    if (form.invalid) {
      return;
    }
    form.value['date'] = this.selectedDate;
    this._adminService.addCalendarTicket(courseID, form.value).subscribe(res => {

      if (res.success) {
        form.reset();
        // this.getConfigData();
        this.configCoursesArray[courseIndex].ticket_configurations.push(res.data.ticket_configurations);
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }

    });

  }

  /**
   *
   * @param courseID
   * @param ticketID
   * @param courseIndex
   * @param ticketIndex
   * @param data
   * @param optionalObj
   */
  private updateTicket(courseID, ticketID, courseIndex, ticketIndex, data, optionalObj = null, event) {
    data.ticket['date'] = this.selectedDate;
    this._adminService.updateCalendarTicket(courseID, ticketID, data).subscribe(res => {
      if (res.success) {
        this.notifierService.notify('success', 'Aggiornato con successo!');

        if (optionalObj) {

          if (optionalObj.type === 'edit' && res.data.ticket) {
            // add new values
            this.configCoursesArray[courseIndex].ticket_configurations[ticketIndex].name = res.data.ticket.name;
            this.configCoursesArray[courseIndex].ticket_configurations[ticketIndex].description = res.data.ticket.description;
            this.configCoursesArray[courseIndex].ticket_configurations[ticketIndex].price = res.data.ticket.price;

            // reset edit form
            this.editForm._results[courseIndex].reset();
            event.target.parentElement.classList.remove('open-edit-row');
          }

        }

      }
    });
  }


  // Time Slots
  deleteTimeTable(courseID, timeSlotID, courseIndex, timeSlotIndex) {

    if (!courseID || !timeSlotID) {
      this.notifierService.notify('error', 'Dati non validi!');
      return;
    }

    this._adminService.deleteCalendarTimeSlot(courseID, timeSlotID).subscribe(res => {

      if (res.success) {
        this.configCoursesArray[courseIndex].time_slot_configurations.splice(timeSlotIndex, 1);
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }

    });
  }

  addNewTimeSlot(event, form, courseID, courseIndex) {
    if (form.invalid) {
      this.notifierService.notify('error', 'Dati non validi!');
      return;
    }

    const _obj = {
      time_slot: {
        start_at: form.value.start_at,
        date: this.selectedDate,
        available_tickets: form.value.available_tickets
      }
    };

    this._adminService.addCalendarTimeSlot(courseID, _obj).subscribe(res => {

      if (res.success) {
        form.reset();
        // this.getCalendarApi();
        this.configCoursesArray[courseIndex].time_slot_configurations.push(res.data.time_slot_configurations);
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }

    });

  }

  updateTimeSlot(courseID, timeSlotID, timeSlotIndex, timeSlotValue) {
    if (!timeSlotValue.value || timeSlotValue.value === '' || !isInt(timeSlotValue.value)) {
      timeSlotValue.classList.add('is-invalid');
      this.notifierService.notify('error', 'Dati non validi!');
      return;
    }

    if (!timeSlotID || timeSlotID === '') {
      this.notifierService.notify('error', 'Dati non validi!');
      return;
    }

    const _obj = {
      time_slot: {
        available_tickets: timeSlotValue.value
      }
    };

    this._adminService.updateCalendarTimeSlot(courseID, timeSlotID, _obj).subscribe(res => {
      if (res.success) {
        timeSlotValue.classList.remove('is-invalid');
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }
    });

  }

  disableTimeSlot(ev, courseIndex) {
    const inputEle = jQuery(ev.target),
      rowID = inputEle.attr('data-row');

    if (!rowID || rowID === '') {
      this.notifierService.notify('success', 'Dati ID non validi!');
      return;
    }

    const slotRow = jQuery('.courseBlock_' + courseIndex + ' .slotRowItem[data-row=' + rowID + ']');

    if (slotRow) {
      slotRow.toggleClass('disabled');
      slotRow.find('input').toggleClass('disabled');
      slotRow.find('.save_btn').toggleClass('disabled');

      slotRow.find('input').attr('disabled', function (_, attr) {
        return !attr;
      });
      slotRow.find('.save_btn').attr('disabled', function (_, attr) {
        return !attr;
      });
    }

  }


  // Datepicker
  // getCopyOfOptions(): IMyDpOptions {
  //   return JSON.parse(JSON.stringify(this.myDatePickerInlineOptions));
  // }

  private dateCounters(datesApi) {

    this.numbers = [];

    const counterDates = [];

    if (datesApi) {

      for (const [key, value] of Object.entries(datesApi)) {
        const parseDate = new Date(key);

        counterDates.push({
          dateObj: {
            year: parseDate.getFullYear(),
            month: parseDate.getMonth() + 1,
            day: parseDate.getDate()
          },
          val: value
        });
      }

    }

    this.numbers = counterDates;
  }

  // private disableDays(datesApi) {
  //   const copy = this.getCopyOfOptions();
  //   console.log(datesApi);
  //   const highlightedDates = [];
  //   // tslint:disable-next-line: forin
  //   for (const d in datesApi) {
  //     const dateSplited = d.split('-');
  //     highlightedDates.push({
  //       year: parseInt(dateSplited[0]),
  //       month: parseInt(dateSplited[1]),
  //       day: parseInt(dateSplited[2])
  //     });
  //   }
  //   // const date = new Date();
  //   // const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  //   // const d = {month: date.getMonth() + 1, year: date.getFullYear(), day: 1};
  //   // const l = {month: lastDay.getMonth() + 1, year: lastDay.getFullYear(), day: lastDay.getDate()};
  //   // copy.disableDateRanges = [{
  //   //   begin: d,
  //   //   end: l
  //   // }];
  //   // copy.disableSince = {month: parsedDate.getMonth() + 1, year: parsedDate.getFullYear(), day: 1};
  //   copy.highlightDates = highlightedDates;
  //   this.cdRef.detectChanges();
  // }

  // private reservedDates(datesApi) {

  //   const copy = this.getCopyOfOptions();

  //   const dates = [];
  //   datesApi.forEach(date => {

  //     const parseDate = new Date(date);

  //     const obj = {
  //       year: parseDate.getFullYear(),
  //       month: parseDate.getMonth() + 1,
  //       day: parseDate.getDate(),
  //     };

  //     dates.push(obj);

  //   });

  //   copy.highlightDates = dates;
  // }


  private getWeekDayName(dateStr) {
    const date = new Date(dateStr);
    return date.toLocaleDateString('it-IT', { weekday: 'short' });
  }

  ngOnDestroy(): void {
    this.$subscriptionCalendar.unsubscribe();
  }


}
