import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { GuideService } from "src/app/services/guide.service";
import { Guide } from "src/app/Models/guide.model";
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from "@angular/forms";
import { NgxSmartModalService } from "ngx-smart-modal";
import { IMyDpOptions, IMyDateModel } from "src/my-date-picker";
import { getFirstDayPreviousMonth } from '../../helpers/helpers';

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@Component({
  selector: "app-guide",
  templateUrl: "./guide.component.html",
  styleUrls: ["./guide.component.scss"],
})
export class GuideComponent implements OnInit {
  guideFilterForm: FormGroup;
  current_guide = "";
  guides = new Array<Guide>();
  isLoading = false;
  isAddLoading = false;
  isEdit = false;
  year: any;
  guideForm: FormGroup;
  editedGuide: Guide;
  selectedGuide: { id: string; name: string } = { id: "", name: "" };
  formMyDatePickerOptions: IMyDpOptions = {
    dateFormat: "dd/mm/yyyy",
    monthLabels: {
      1: "Gennaio",
      2: "Febbraio",
      3: "Marzo",
      4: "Aprile",
      5: "Maggio",
      6: "Giugno",
      7: "Luglio",
      8: "Agosto",
      9: "Settembre",
      10: "Ottobre",
      11: "Novembre",
      12: "Dicembre",
    },
    dayLabels: {
      su: "Dom",
      mo: "Lun ",
      tu: "Mar",
      we: "Mer",
      th: "Gio",
      fr: "Ven",
      sa: "Sab",
    },
    sunHighlight: false,
    selectorHeight: "auto",
    selectorWidth: "410px",
    allowSelectionOnlyInCurrentMonth: false,
    disableHeaderButtons: true,
    yearSelector: false,
    monthSelector: false,
    showTodayBtn: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: false,
    editableDateField: false,
    showSelectorArrow: false,
    inline: false,
  };
  date: Date = new Date();
  fromDate: any = {
    year: this.date.getFullYear(),
    month: this.date.getMonth(),
    day: this.date.getDate(),
  };
  toDate: any = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  public showNotes: boolean;

  constructor(
    private guideService: GuideService,
    private formBuilder: FormBuilder,
    private modalService: NgxSmartModalService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.getGuide();
    this.guideForm = new FormGroup({
      name: new FormControl("", Validators.required),
      cognome: new FormControl("", Validators.required),
      email: new FormControl("", Validators.required),
      phone_number: new FormControl("", Validators.required),
      notesValue: new FormControl({ value: "", disabled: true }),
    });
    this.guideFilterForm = this.formBuilder.group({
      guide_id: [""],
      from_date: [""],
      to_date: [""],
    });
  }

  ngAfterViewInit() {
    this.setDate();
  }
  getGuide() {
    this.guideService.getGuides().subscribe((respObj) => {
      this.guides = respObj.data.guides;
      this.isLoading = false;
    });
  }

  onDateChangedFrom(event: IMyDateModel) {
    this.fromDate = event.date;
  }

  onDateChangedTo(event: IMyDateModel) {
    this.toDate = event.date;
  }

  setDate() {
    let date = new Date();
    let prevDate = getFirstDayPreviousMonth();
    this.guideFilterForm.patchValue({
      from_date: {
        date: {
          year: prevDate.getFullYear(),
          month: prevDate.getMonth() + 1,
          day: prevDate.getDate(),
        },
      },
    });
    this.guideFilterForm.patchValue({
      to_date: {
        date: {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
        },
      },
    });

    this.cdr.detectChanges();
  }

  addGuide() {
    //this.isEdit = false;
    this.isAddLoading = false;
    this.cdr.detectChanges();
    const guide = new Guide();
    guide.first_name = this.name.value;
    guide.last_name = this.cognome.value;
    guide.email = this.email.value;
    guide.phone_number = this.phone_number.value;
    guide.notes = this.guideForm.value.notesValue;
    if (this.isEdit) {
      this.editedGuide.first_name = guide.first_name;
      this.editedGuide.last_name = guide.last_name;
      this.editedGuide.email = guide.email;
      this.editedGuide.phone_number = guide.phone_number;
      this.editedGuide.notes = guide.notes;
      this.guideService.editGuide(this.editedGuide).subscribe(console.log);
      this.isEdit = false;
      this.getGuide();
      this.cdr.detectChanges();
      this.modalService.close("guideAddModal");
      return;
    }
    this.guideService.createGuide(guide).subscribe((resp: any) => {
      if (resp && resp.success) {
        this.modalService.close("guideAddModal");
        this.guideForm.reset();
        this.guides.push(resp.data.guide);
        this.getGuide();
        this.cdr.detectChanges();
      }
    });
  }

  onToDateChangedTo(event) {}

  edit(guide: Guide) {
    this.isEdit = true;
    this.showNotes = !!guide.notes;
    this.showNotes
      ? this.guideForm.get("notesValue").enable()
      : this.guideForm.get("notesValue").disable();
    this.name.setValue(guide.first_name);
    this.cognome.setValue(guide.last_name);
    this.email.setValue(guide.email);
    this.phone_number.setValue(guide.phone_number);
    this.guideForm.patchValue({ notesValue: guide.notes });
    this.editedGuide = guide;
    this.modalService.open("guideAddModal");
  }
  delete(guide: Guide) {
    this.guideService.deleteGuide(guide).subscribe((resp: any) => {
      if (resp.success) {
        this.isEdit = false;
        this.guides.splice(
          this.guides.findIndex((g) => g.id == guide.id),
          1
        );
      }
    });
  }
  openModal() {
    this.isEdit = false;
    this.modalService.open("guideAddModal");
  }

  // onDateChanged(event: IMyDateModel) {
  //   this.fromDate = event.date;
  //   this.getCalendarApi(this.current_guide);
  // }

  // onToDateChangedTo(event: IMyDateModel) {
  //   this.toDate = event.date;
  //   this.getCalendarApi(this.selectedLocation.id);
  // }

  changeGuide(event) {}

  insertGuide() {
    this.guideForm.reset();
    this.openModal();
  }
  get name() {
    return this.guideForm.get("name");
  }
  get cognome() {
    return this.guideForm.get("cognome");
  }
  get email() {
    return this.guideForm.get("email");
  }
  get phone_number() {
    return this.guideForm.get("phone_number");
  }

  exportGuide(guide_id) {
    var from = "";
    var to = "";
    if (this.fromDate) {
      from =
        this.fromDate["year"] +
        "-" +
        this.fromDate["month"] +
        "-" +
        this.fromDate["day"];
    }
    if (this.toDate) {
      to =
        this.toDate["year"] +
        "-" +
        this.toDate["month"] +
        "-" +
        this.toDate["day"];
    }
    this.guideService.exportGuide(from, to, guide_id);
  }

  onShowNotesChange() {
    this.showNotes = !this.showNotes;
    this.showNotes
      ? this.guideForm.get("notesValue").enable()
      : this.guideForm.get("notesValue").disable();
  }
}
