import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { IMyDpOptions, IMyDateModel } from "src/my-date-picker";
import { SharedService } from "src/app/services/shared.service";
import { AdminService } from "src/app/services/admin.service";
import { AttrazioniComponent } from "../attrazioni/attrazioni.component";
import { AttrazioniService } from "src/app/services/attrazioni.service";
import { CalendarioService } from "src/app/services/calendario.service";
import { OrderService } from "src/app/services/order.service";
import { GuideService } from "src/app/services/guide.service";
import { Guide } from "src/app/Models/guide.model";
import { NotifierService } from "angular-notifier";
import { getFirstDayPreviousMonth } from '../../helpers/helpers';
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";

@Component({
  selector: "app-bookings",
  templateUrl: "./bookings.component.html",
  styleUrls: ["./bookings.component.scss"],
})
export class BookingsComponent implements OnInit {
  highlightedDates = [];
  totalAmount = 0.0;
  date: Date = new Date();

  locations: Array<any> = [];

  currentLocation: string;
  calendarData: any = null;
  selectedLocation: { id: string; name: string } = { id: "", name: "" };
  fromDate: any;
  toDate: any;
  selectedDate: string = moment().format("YYYY-MM-DD");

  ordersData: any;
  urlCsv: string;

  isLoading = false;
  month: string;
  year: string;
  filters: { guide_id: string; event_status: string; order_status: string } = {
    guide_id: "",
    event_status: "",
    order_status: "",
  };

  private subscriptionTwo$: Subscription;
  formMyDatePickerOptions: IMyDpOptions = {
    dateFormat: "dd/mm/yyyy",
    monthLabels: {
      1: "Gennaio",
      2: "Febbraio",
      3: "Marzo",
      4: "Aprile",
      5: "Maggio",
      6: "Giugno",
      7: "Luglio",
      8: "Agosto",
      9: "Settembre",
      10: "Ottobre",
      11: "Novembre",
      12: "Dicembre",
    },
    dayLabels: {
      su: "Dom",
      mo: "Lun ",
      tu: "Mar",
      we: "Mer",
      th: "Gio",
      fr: "Ven",
      sa: "Sab",
    },
    sunHighlight: false,
    selectorHeight: "auto",
    selectorWidth: "410px",
    alignSelectorRight: true,
    allowSelectionOnlyInCurrentMonth: false,
    disableHeaderButtons: true,
    yearSelector: false,
    monthSelector: false,
    showTodayBtn: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: false,
    editableDateField: false,
    showSelectorArrow: false,
    inline: false,
  };

  localeIt: any = "it";

  todayDate = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };

    page: number = 1;
  per_page: number = 20;
  total: number;

  filterData: any = {
    name: "",
  };
  calendarForm: FormGroup;

  ordersFiltered: any;
  csv: boolean = false;
  current_location: string = "";

  guides = new Array<any>();
  constructor(
    private _sharedService: SharedService,
    private _adminService: AdminService,
    private fb: FormBuilder,
    private notifierService: NotifierService,
    private attractionService: AttrazioniService,
    private orderService: OrderService,
    private cdRef: ChangeDetectorRef,
    private guideService: GuideService
  ) {}

  ngOnInit() {
    this.calendarForm = this.fb.group({
      attraction: ["", Validators.required],
      from_date: [""],
      to_date: [""],
      guide_id: [""],
    });

    // == Get orders by location
    this.attractionService.getAttractionsForSelectbox().subscribe((res) => {
      if (res && res.success) {
        this.locations = res.data.attractions;
        this.guideService.getGuidesForBox().subscribe((r: any) => {
          if (r && r.success) {
            this.guides = r.data.guides;
          }
        });
        this.currentLocation = this.locations[0].id;
        // == Get location from localStorage
        // const loc = localStorage.getItem('current_location_revelia');
        // if (loc) {
        //   this.currentLocation = loc;
        // } else {
        //   this.currentLocation = this.locations[0].id;
        // }

        this.selectedLocation = {
          id: this.locations[0].id,
          name: this.locations[0].name,
        };
        // this.getOrdersDates();
        this.getOrders();
        this.cdRef.detectChanges();
      }
    });
  }

  ngAfterViewInit() {
    this.setDate();
    this.calendarForm.patchValue({ attraction: this.currentLocation });
    this.cdRef.detectChanges();
  }

  pageChanged(page: number) {
    this.page = page;
    this.getOrders();
  }

  setDate() {
    let date = new Date();
    let prevDate = getFirstDayPreviousMonth();
    this.calendarForm.patchValue({
      from_date: {
        date: {
          year: prevDate.getFullYear(),
          month: prevDate.getMonth() + 1,
          day: prevDate.getDate(),
        },
      },
    });
    this.calendarForm.patchValue({
      to_date: {
        date: {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
        },
      },
    });

    this.cdRef.detectChanges();
  }

  getOrdersDates() {
    this.orderService
      .getOrdersByDate(
        this.selectedLocation.id,
        this.selectedDate,
        this.filters
      )
      .subscribe((res: any) => {
        if (res && res.success) {
          this.csv = false;
          this.ordersData = res.data.orders;
          this.cdRef.detectChanges();
        }
      });
  }
  toggleElement(event) {
    const targetParent = event.currentTarget;
    if (
      event.target.className.includes(`fa-print`) ||
      event.target.className.includes(`fa-trash-alt`)
    ) {
      return;
    }
    targetParent.parentElement.classList.toggle("open");
    targetParent.parentElement.children[1].classList.toggle("show");
  }

  changeLocation(ev) {
    this.page = 1;
    this.selectedLocation = {
      id: ev.target.value,
      name: ev.target.options[ev.target.selectedIndex].getAttribute("name"),
    };
    localStorage.setItem("current_location_revelia", ev.target.value);
    // this.getOrdersDates();
    this.getOrders();
  }

  onSearchName() {
    this.page = 1;
    const timeSlots = this.ordersData.time_slots;

    if (!this.filterData.name || this.filterData.name === "") {
      this.ordersData = this.ordersFiltered;
      return;
    }

    const filteredTimeSlots = {
      time_slots: [],
      total: this.ordersData.total,
      rersevation_counter: this.ordersData.rersevation_counter,
    };

    timeSlots.forEach((slot, slotIndex) => {
      if (slot.orders && slot.orders.length > 0) {
        const orders = slot.orders;

        const foundOrder = orders.filter((order) => {
          const name = order.user_name.toLowerCase();
          const course_name = order.course_name.toLowerCase();
          return (
            name.includes(this.filterData.name.toLowerCase()) ||
            course_name.includes(this.filterData.name.toLowerCase())
          );
        });

        console.log(foundOrder, slotIndex);

        if (foundOrder && foundOrder.length > 0) {
          filteredTimeSlots.time_slots.push({
            course_name: timeSlots[slotIndex].course_name,
            start_at: timeSlots[slotIndex].start_at,
            availabe_tickets: timeSlots[slotIndex].availabe_tickets,
            booked_tickets: timeSlots[slotIndex].booked_tickets,
            orders: foundOrder,
            pending_payments: timeSlots[slotIndex].pending_payments,
          });
        }
      }
    });

    console.log(filteredTimeSlots);

    this.ordersData = filteredTimeSlots;
  }

  // onDateChanged(event: IMyDateModel) {
  //   this.selectedDate = event.formatted;
  //   this.getOrdersDates();
  // }
  onDateChanged(event: IMyDateModel) {
    this.fromDate = event.date;
    this.getOrders();
  }

  onToDateChangedTo(event: IMyDateModel) {
    this.toDate = event.date;
    this.getOrders();
  }

  getOrders() {
    var from = "";
    var to = "";
    if (this.fromDate) {
      from =
        this.fromDate["year"] +
        "-" +
        this.fromDate["month"] +
        "-" +
        this.fromDate["day"];
    }
    if (this.toDate) {
      to =
        this.toDate["year"] +
        "-" +
        this.toDate["month"] +
        "-" +
        this.toDate["day"];
    }
    if (!this.selectedLocation.id) return;
    this.orderService
      .getOrders(this.selectedLocation.id, from, to, this.filters, this.page, this.per_page)
      .subscribe((res: any) => {
        if (res && res.success) {
          this.calendarData = res.data.orders;
          this.total = res.data.total;
          this.page = res.data.pagination.current_page;
          this.per_page = res.data.pagination.per_page;
          this.cdRef.detectChanges();
        }
        this.isLoading = false;
      });
  }

  // onCalendarViewChanged(event){
  //   this.month = event.month;
  //   this.year = event.year;
  //   this.getOrders(this.month, this.year);
  // }

  // get copyOfOptions() {
  //   //return this.myDatePickerInlineOptions;
  //   return JSON.parse(JSON.stringify(this.myDatePickerInlineOptions));
  // }

  downloadCsv() {
    let params: Array<string> = new Array<string>();
    params.push(`attraction_id=${this.selectedLocation.id}`);
    Object.keys(this.filters).forEach((filter) => {
      if (this.filters[filter])
        params.push(`${filter}=${this.filters[filter]}`);
    });
    if (this.fromDate) {
      params.push(
        `date_from=${this.fromDate["year"]}-${
          this.fromDate["month"] > 9
            ? this.fromDate["month"]
            : "0" + this.fromDate["month"]
        }-${
          this.fromDate["day"] > 9
            ? this.fromDate["day"]
            : "0" + this.fromDate["day"]
        }`
      );
    }
    if (this.toDate) {
      params.push(
        `date_to=${this.toDate["year"]}-${
          this.toDate["month"] > 9
            ? this.toDate["month"]
            : "0" + this.toDate["month"]
        }-${
          this.toDate["day"] > 9 ? this.toDate["day"] : "0" + this.toDate["day"]
        }`
      );
    }
    this.orderService.getCsv(params).subscribe((res: any) => {
      var a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(res);
      a.download = "Prenotazioni.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }

  cancelReservation(order_id, res_id) {
    this.orderService
      .cancelReservation(order_id, res_id)
      .subscribe((res: any) => {
        if (res.success) {
          this.notifierService.notify(
            "success",
            "Prenotazione annullata con successo!"
          );
          //  this.getOrdersDates();
          this.getOrders();
        }
      });
  }

  openPDF(order_id, event_id) {
    this.orderService.openPDF(order_id, event_id);
  }

  resetFilter() {
    this.current_location = "";
    this.selectedLocation = {
      id: "",
      name: "",
    };
    this.setDate();
    this.getOrders();
    //this.getCalendarApi("");
  }
}
