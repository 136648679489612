import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CalendarioService {

  private headers: HttpHeaders;
  notifier: NotifierService;
  private API_URL = environment.API;
  constructor(
    private _http: HttpClient,
    private _notifierService: NotifierService,
  ) {
    this.notifier = this._notifierService;
    this.headers = new HttpHeaders().set('Content-Type', 'application/json');
    // const userData = JSON.parse(localStorage.getItem('userData'));
    this.headers.append('access-token', localStorage.getItem('accessToken'));
    this.headers.append('client', localStorage.getItem('client'));
    this.headers.append('token-type', localStorage.getItem('tokenType'));
    this.headers.append('uid', localStorage.getItem('uid'));
  }

  // == Eventss
  getEvents(): Observable<any> {
    return this._http.get(this.API_URL + '/admin/events').pipe(
      map((res: any) => {
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify('error', error.error.errors[0]);
        } else {
          this.notifier.notify('error', 'Si è verificato un errore!');
        }
        return of(result as T);
      })
    );
  }
  createEvents(attraction_id, e, guide_id) {
    const d_config = e.date_configuration;
    const event = {attraction_id, date_configuration: {
      start_at: d_config.start_at, end_at: d_config.end_at, date_event: d_config.date_event , expire_at: d_config.expire_at,
      max_seats: d_config.max_seats, min_seats: d_config.min_seats,
      guide_id}};
    console.log(event);
    return this._http.post(this.API_URL + '/admin/events', {event}).pipe(
      map((res: any) => {
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify('error', error.error.errors[0]);
        } else {
          this.notifier.notify('error', 'Si è verificato un errore!');
        }
        return of(result as T);
      })
    );
  }
  editEvents(event) {
    return this._http.put(this.API_URL + `/admin/events/${event.id}`, event).pipe(
      map((res: any) => {
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify('error', error.error.errors[0]);
        } else {
          this.notifier.notify('error', 'Si è verificato un errore!');
        }
        return of(result as T);
      })
    );
  }
  deleteEvents(event) {
    return this._http.delete(this.API_URL + `admin/events/${event.id}`).pipe(
      map((res: any) => {
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify('error', error.error.errors[0]);
        } else {
          this.notifier.notify('error', 'Si è verificato un errore!');
        }
        return of(result as T);
      })
    );
  }

  getCalendar(locationID: string, from_date: string, to_date:string): Observable<any> {
    console.log(locationID);
    var url = 'admin/events/calendar';
    if(locationID){
      url += '?attraction_id='+locationID;
      if(from_date){
        url += '&from_date=' + from_date;
      }
      if(to_date){
        url += '&to_date=' + to_date;
      }
    }else{
      if(from_date){
        url += '?from_date=' + from_date;
        if(to_date){
          url += '&to_date=' + to_date;
        }
      }else{
        url += '?to_date=' + to_date;
      }
    }
    return this._http.get(this.API_URL + url
    ).pipe(
      map((res: any) => {
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {
        if (error.error.errors) {
          this.notifier.notify('error', error.error.errors[0]);
        } else {
          this.notifier.notify('error', 'Si è verificato un errore!');
        }
        return of(result as T);
      })
    );
  }

}
