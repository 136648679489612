import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {NotifierService} from 'angular-notifier';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IMyDateModel, IMyDpOptions} from '../../../my-date-picker/interfaces';
import {NgxSmartModalService} from 'ngx-smart-modal';
import * as moment from 'moment';
import {SharedService} from '../../services/shared.service';

@Component({
  selector: 'app-config-closures',
  templateUrl: './config-closures.component.html',
  styleUrls: ['./config-closures.component.scss']
})
export class ConfigClosuresComponent implements OnInit, OnDestroy {

  locations: Array<any> = [];
  currentLocation: string;
  selectedLocation: { id: string, name: string } = {id: '', name: ''};

  selectedLocationClosign: { id: string, name: string } = {id: '', name: ''};

  closuresData: Array<any> = [];

  isLoading: boolean = false;

  isAddLoading: boolean = false;

  isSubmitted: boolean = false;

  private subscriptionTwo$: Subscription;

  closuresForm: FormGroup;

  private date: Date = new Date();

  todayDate: any = {year: 0, month: 0, day: 0};

  myDatePickerInlineOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    minYear: this.date.getFullYear() - 1,
    maxYear: this.date.getDate(),
    monthLabels: {
      1: 'Gennaio',
      2: 'Febbraio',
      3: 'Marzo',
      4: 'Aprile',
      5: 'Maggio',
      6: 'Giugno',
      7: 'Luglio',
      8: 'Agosto',
      9: 'Settembre',
      10: 'Ottobre',
      11: 'Novembre',
      12: 'Dicembre'
    },
    disableUntil: {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() - 1},
    disableDays: [{year: 0, month: 0, day: 0}],
    // disableSince: {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() + 1},
    selectorHeight: 'auto',
    selectorWidth: '410px',
    allowSelectionOnlyInCurrentMonth: false,
    disableHeaderButtons: true,
    yearSelector: false,
    monthSelector: false,
    showTodayBtn: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: false,
    editableDateField: false,
    showSelectorArrow: false,
    inline: false
  };

  localeIt: any = 'it';

  constructor(
    private _adminService: AdminService,
    private _sharedService: SharedService,
    private notifierService: NotifierService,
    private formBuilder: FormBuilder,
    public ngxSmartModalService: NgxSmartModalService) {
    this.todayDate = {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate()};
  }

  ngOnInit() {

    // == Get Locations
    this._sharedService.getLocation.subscribe(location => {

      this.locations = location;
      // == Get location from localStorage
      let loc = localStorage.getItem('current_location');
      if(loc){
        this.currentLocation = loc;
      
      }else{
        this.selectedLocation = {
          id: this.locations[0].id,
          name: this.locations[0].name,
        };
        this.currentLocation = this.locations[0].id;
      }


      // get closures first init
      this.getClosuresApi(this.currentLocation);

    });

    // == Form Builder
    this.closuresForm = this.formBuilder.group({
      location: ['', Validators.required],
      date: [this.todayDate, Validators.required],
      public_only: ['', Validators.required],
      description: '',
    });
  }

  changeLocation(ev) {

    this.selectedLocation = {
      id: ev.target.value,
      name: ev.target.options[ev.target.selectedIndex].getAttribute('name')
    };
    localStorage.setItem('current_location', ev.target.value);
    this.changeLocationClosing(null);

    this.getClosuresApi(ev.target.value);
  }

  changeLocationClosing(ev) {
    let current_location: any;
    current_location = localStorage.getItem('current_location');
    if(current_location){
      this.selectedLocationClosign = current_location;
      this.closureControls.location.setValue(current_location, {
        onlySelf: true
      });
    }
    else{
      this.selectedLocationClosign = {
        id: ev.target.value,
        name: ev.target.options[ev.target.selectedIndex].getAttribute('name')
      };
      this.closureControls.location.setValue(ev.target.value, {
        onlySelf: true
      });
    }
  }

  deleteClosure(closureID: string, locationID: string, index) {
    if (!closureID || closureID === '' || !locationID || locationID === '') {
      this.notifierService.notify('error', 'ID non presente!');
      return;
    }
    this._adminService.deleteKalataClosure(closureID, locationID).subscribe(res => {
      if (res.success) {
        this.closuresData.splice(index, 1);
        this.notifierService.notify('success', 'Aggiornato con successo!');
      }
    });
  }

  // New Closure
  get closureControls() {
    return this.closuresForm.controls;
  }

  openClosureModal() {
    this.ngxSmartModalService.setModalData({}, 'configClosuresModal');
    this.ngxSmartModalService.open('configClosuresModal');
  }

  addClosure() {

    if (!this.closuresForm.valid) {
      return false;
    }

    //create post data _obj
    const formValue = this.closuresForm.value;

    this.isSubmitted = this.isAddLoading = true;

    let data = {

      closing: {
        date: moment.unix(formValue.date.epoc).format('YYYY-MM-DD'),
        description: formValue.description,
        public_only: formValue.public_only
      }
    };

    this._adminService.addKalataClosure(data, formValue.location).subscribe(res => {
      this.isAddLoading = false;
      this.closuresData.push(res.data.closing);
      this.ngxSmartModalService.close('configClosuresModal');
    });

  }

  /**
   *
   * @param locationId
   */
  private getClosuresApi(locationId: string) {
    this.isLoading = true;
    this.subscriptionTwo$ = this._adminService.getClosures(locationId).subscribe(res => {
      this.closuresData = res.success ? res.data.closings : null;
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.subscriptionTwo$.unsubscribe();
  }

}
