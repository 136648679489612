import {Component, OnInit} from '@angular/core';
import {SharedService} from '../../services/shared.service';
import {AdminService} from '../../services/admin.service';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {

  locations: Array<any> = null;

  constructor(
    private _adminService: AdminService,
    private _sharedService: SharedService,
    private notifierService: NotifierService
  ) {
  }

  ngOnInit() {

    //== Get locations
    this._sharedService.getLocation.subscribe(res => {
      this.locations = res;
    });
  }

  changeStatus(e, locationID) {

    const data = {
      location: {
        'online': e.target.checked
      }
    };

    this._adminService.updateLocationStatus(locationID, data).subscribe(res => {
      if (res.success) {
        if(res.data && res.data.location){
          if(res.data.location.online){
            this.notifierService.notify('success', 'Location online');
          }else{
            this.notifierService.notify('success', 'Location offline');
          }
        }
        this._sharedService.updateLocationStatus(locationID, e.target.checked);
      }
    });
  }

}
