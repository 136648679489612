export class Guide {
  id = "";
  created_at = 1594202210;
  updated_at = 1594202210;
  first_name = "";
  last_name = "";
  email = "";
  phone_number = "";
  fiscal_code = "";
  notes = "";
}
