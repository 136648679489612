import {Component, OnInit} from '@angular/core';
import {SharedService} from '../services/shared.service';
import {AdminService} from '../services/admin.service';

@Component({
  selector: 'app-restricted',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(private _sharedService: SharedService,
              private _adminService: AdminService) {
  }

  ngOnInit() {

    //Set Locations Globally
    this._adminService.getLocations().subscribe(res => {
      if (res.success) {
        this._sharedService.addLocation(res.data.banners);
      }
    });
  }

}
