import {Component, OnInit} from '@angular/core';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  date: Date = new Date();

  months: Array<any> = [];

  years: Array<number> = [];

  isLoading: boolean = false;

  dashboardData: any;

  selectedDate: { year: number, month: number } = {year: 0, month: 0};

  constructor(
    private _adminService: AdminService
  ) {
  }

  ngOnInit() {

    //fill Select box
    this.getYears();
    this.getMonths();

    //dashboard data
    this.selectedDate = {
      month: this.date.getMonth() + 1,
      year: this.date.getFullYear(),
    };

    this.getDashboardByDate(this.selectedDate);
  }


  yearChange(ev) {
    this.selectedDate.year = ev.target.value;
    this.getDashboardByDate(this.selectedDate);
  }

  monthChange(ev) {
    this.selectedDate.month = ev.target.value;
    this.getDashboardByDate(this.selectedDate);
  }

  private getDashboardByDate(date: object) {

    this.isLoading = true;

    this._adminService.getDashboardData(date).subscribe(res => {
      this.isLoading = false;
      this.dashboardData = res.data;
    });
  }

  private getYears() {
    let yy = this.date.getFullYear();
    for (let i = (yy - 10); i <= yy; i++) {
      this.years.push(i);
    }
  }

  private getMonths() {

    this.months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
  }

  monthNameToNum(monthname) {
    let month = this.months.indexOf(monthname);
    return month != -1 ? month + 1 : undefined;
  }
}



