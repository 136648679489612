import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {AdminService} from '../../../services/admin.service';
import * as moment from '../../booking/booking.component';
import {SharedService} from '../../../services/shared.service';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit, OnDestroy {

  pageTitle: string = '';
  locations: Array<any> = null;

  private subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private _adminService: AdminService,
    private _sharedService: SharedService,
  ) {
  }

  ngOnInit() {

    //get locations
    this._sharedService.getLocation.subscribe(locations => {
      this.locations = locations;
    });

    //get page title route
    this.subscription = this.route.data.subscribe(v => this.pageTitle = v.title);

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
